
const EVENTS_NAMESPACE = "events";

const MUTATIONS = Object.freeze({
    ADD_EVENT_FROM_ADMIN: "addEventFromAdmin",
    ADD_EVENT_FROM_CLIENT: "addEventFromClient",
    REMOVE_EVENT_FROM_ADMIN: "removeEventFromAdmin",
    MODIFY_LIST_MAIN_LUNCH: "modifyListMainLunch",
    MODIFY_LIST_LUNCH_BEVERAGE: "modifyListLunchBeverage",
});

const ACTIONS = Object.freeze({
    VALIDATE_LIST_DATA: "validateListData",
    VALIDATE_PAYLOAD_SUBMIT: "validatePayloadSubmit",
});

export { EVENTS_NAMESPACE, MUTATIONS, ACTIONS }