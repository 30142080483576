<template lang="pug">
div.d-flex.justify-content-end
    
    div.senderBody
        p(class="message") {{ message }}
        div(class="d-flex justify-content-end sender-info")
            p.text-right {{ caregiverName }}
            div.d-flex.justify-content-end(class="time-date")
                p.text-right {{ time }}
    div(class="sender-image d-flex flex-column p-2 rounded")
        img(class="rounded-circle" 
            :src="currentUserPhoto"
        alt="user photo" width="30" height="30")
        

    
</template>

<script>
import fallbackClientImg from '@/assets/user.png';
export default {
    props: {
        id:{
            type:String,
        },
        name: {
            type: String,
        },
        message: {
            type: String,
        },
        time: {
            type: String,
        },
    },
   
    computed:{
        caregiverName() {
          return this.$store.state?.caregiver?.name;
        },
        currentUserPhoto() {
            return this.$store.state?.users?.find(u => u.id === this.$store.state?.cli_id)?.photo || fallbackClientImg
        },
        fallbackImg() {
            return fallbackClientImg;
        }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple: #50276B;

.senderBody {
    background-color: $base-color-purple;
    border-radius: 10px;
    color: white;
    width: 60%;
    max-width: 75%;
    height: auto;
    padding: 10px;

    p{
        margin: 0;
        
    }
    .message{
        font-size: 1rem;
        white-space: pre-wrap;
        padding: 1px
    }
    .sender-info{
        font-size: 0.8rem;
        white-space: nowrap;
        color: #f1f1f150;
        flex-direction: column;
        .bubble{
            background-color: #f1f1f160;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
    
}
.sender-image{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>