import { z } from "zod";
import { MUTATIONS, ACTIONS } from "./types-events";

const events = {
    namespaced: true,
    state: {
        listData: [],
        listMainLunch: [],
        listLunchBeverage: [],

    },
    mutations: {
        [MUTATIONS.ADD_EVENT_FROM_ADMIN]: (state, payload) => {
            state.listData.push(payload);
            console.log('payload mutation add',payload)
        },
        [MUTATIONS.ADD_EVENT_FROM_CLIENT]: (state, { msgId, attributePayload }) => {
            state.listData.find(item => item.msgId === msgId).caregivers.push(attributePayload);
        },
        [MUTATIONS.REMOVE_EVENT_FROM_ADMIN]: (state, msgId) => {
            state.listData = state.listData.filter(item => item.msgId !== msgId);
        },
        [MUTATIONS.MODIFY_LIST_MAIN_LUNCH]: (state, payloadListData) => {
            // console.log('payloadListDataMain',payloadListData)
            state.listMainLunch = [];
            payloadListData.forEach(item => {
                const msgId = item.msgId;
                const listMainLunch = item.lunch?.listMain || [];
                // console.log('item main', item)
                state.listMainLunch.push({
                    msgId,
                    listMainLunch,
                    mainLunchSelected: {
                        caregiver: {
                            main: listMainLunch[0] || '',
                        },
                        client: {
                            main: listMainLunch[0] || '',
                        }
                    },
                });

            });
        },
        [MUTATIONS.MODIFY_LIST_LUNCH_BEVERAGE]: (state, payloadListData) => {
            // console.log('payloadListDatabeverage',payloadListData)
            state.listLunchBeverage = [];
            payloadListData.forEach(item => {
                const msgId = item.msgId;
                const listLunchBeverage = item.lunch?.listBeverage || [];
                // console.log('item beverage', item)
                state.listLunchBeverage.push({
                    msgId,
                    listLunchBeverage,
                    beverageLunchSelected: {
                        caregiver: {
                            beverage: listLunchBeverage[0] || '',
                        },
                        client: {
                            beverage: listLunchBeverage[0] || '',
                        }
                    },
                });

            });   
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        [ACTIONS.VALIDATE_LIST_DATA]: async (_, payload) => {
            // console.log('payload action validate',payload)
            try {
                const SchemaEventListData = z.object({
                    msgId: z.string(),
                    convId: z.string(),
                    title: z.string(),
                    listGroup: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                    })),
                    lunch: z.object({
                        listMain: z.array(
                            z.string()
                        ).optional(),
                        listBeverage: z.array(
                            z.string()
                        ).optional(),
                    }),
                    description: z.string(),
                    itinerary: z.array(
                        z.object({
                            time: z.string(),
                            description: z.string(),
                        })
                    ).min(0),
                    pricePerPerson: z.number(),
                    gst: z.number().step(0.01),
                    date: z.string().datetime(),
                    admin: z.string(),
                    media: z.string().nullish(),
                    isEventConversation: z.boolean(),
                    caregivers: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                        isAttending: z.boolean().nullable(),
                        isCaregiverCome: z.boolean(),
                        listLunchSelected: z.object({
                            caregiver: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                            client: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                        }),
                        client: z.object({
                            id: z.string(),
                            name: z.string(),
                        }),
                        otherPersonJoining: z.array(
                            z.object({
                                name: z.string(),
                                lunch: z.object({
                                    main: z.string(),
                                    beverage: z.string(),
                                }),
                            })
                        ).min(0),
                        paymentInfo: z.object({
                            totalPrice: z.number(),
                            typeOfPayment: z.enum(["online", "cash", "cheque", "not applicable"]).nullable(),
                            proofOfPayment: z.object({
                                cashMemo: z.string().nullable(),
                                onlineReceiptId: z.string().nullable(),
                                chequeNo: z.string().nullable(),
                            }),
                            paymentStatus: z.boolean(),
                            paymentDate: z.string().datetime().nullable(),
                        }).strict(),
                    })).min(0),
                }).strict();
    
                SchemaEventListData.parse(payload);
                
            } catch (error) {
                console.error(error);
            }

        },
        [ACTIONS.VALIDATE_PAYLOAD_SUBMIT]: async (_, payload) => {
            // console.log('doing validate payload')
            const SchemaPayloadSubmit = z.object({
                msgId: z.string(),
                convId: z.string(),
                media: z.string().nullish(),
                attributePayload: z.object({
                    title: z.string(),
                    listGroup: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                    })),
                    lunch: z.object({
                        listMain: z.array(
                            z.string()
                        ).optional(),
                        listBeverage: z.array(
                            z.string()
                        ).optional(),
                    }),
                    itinerary: z.array(
                        z.object({
                            time: z.string(),
                            description: z.string(),
                        })
                    ).min(0),
                    pricePerPerson: z.number(),
                    gst: z.number().step(0.01),
                    date: z.string().datetime(),
                    admin: z.string(),
                    isEventConversation: z.boolean(),
                    caregivers: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                        isAttending: z.boolean().nullable(),
                        isCaregiverCome: z.boolean(),
                        listLunchSelected: z.object({
                            caregiver: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                            client: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                        }),
                        client: z.object({
                            id: z.string(),
                            name: z.string(),
                        }),
                        otherPersonJoining: z.array(
                            z.object({
                                name: z.string(),
                                lunch: z.object({
                                    main: z.string(),
                                    beverage: z.string(),
                                }),
                            }).strict(),
                        ).min(0),
                        paymentInfo: z.object({
                            totalPrice: z.number(),
                            typeOfPayment: z.enum(["online", "cash", "cheque", "not applicable"]).nullable(),
                            proofOfPayment: z.object({
                                cashMemo: z.string().nullable(),
                                onlineReceiptId: z.string().nullable(),
                                chequeNo: z.string().nullable(),
                            }).strict(),
                            paymentStatus: z.boolean(),
                            paymentDate: z.string().datetime().nullable(),
                        }).strict(),
                    })).min(0),
                }).required()
            }).strict();

            try {
                let validation = SchemaPayloadSubmit.parse(payload);
                console.log('validation',validation)
            } catch (e) {
                console.error('validation error', e.error)
            }
            
            
        },

    },

}

export default events;