<template lang="pug">
div(class="main-container" )
    consentModal(:consent='consent' :existingRecord='existingRecord')
    b-modal#modalPermission(centered, title="Allow Notifcations" hide-footer)
                p(class="text-center") Click Allow for push notifcations from announcements and messages!
                div(class="d-flex justify-content-center")
                    b-button(class=" mt-4 px-5 shadow" variant="success" class="mt-3"  size="md" @click="requestPermission")
                        | Allow
    div(class="greeting-container w-100 d-flex p-2 " style="gap: 1rem; margin: 0 auto;")
        lottie-animation(:animation-data="timeOfDayGreet === 'Morning' ? sun : moon" :autoPlay="true" :loop="false" :speed="1" ref="anim"  class="w-25" v-if="!loadingImage && caregiverName")
        b-skeleton(height="100px" width="100px" variant="rect" class="w-25 ml-2 mt-2" v-else-if="loadingImage || !caregiverName")
        
        div(class="greet-text d-flex flex-column justify-content-center align-items-start w-75 p-2" v-if="caregiverName")
            h6(class="text-start") Good {{timeOfDayGreet}}, 
            h3(class="text-start") {{caregiverName}}
        div(class="name-skeleton d-flex flex-column justify-content-center align-items-start w-50 p-2" v-if="!caregiverName")
            b-skeleton(height="40px" width="100%" variant="rect")
            b-skeleton(height="40px" width="100%" variant="rect")
    
    div(class="dsg-image-container w-100 px-3")

        b-card(overlay :img-src="validImageUrl && validImageUrl" img-alt="Card Image" text-variant="white" v-if="validImageUrl && !loadingImage")
            #card-overlay.d-flex.flex-column.justify-content-end.h-100
                h4(style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);") Welcome to DSG FOW

    
        div(v-else-if="loadingImage || !validImageUrl" class="d-flex flex-column w-100 h-100")
            b-skeleton(img class="skeleton-image" height="250px" width="100%" variant="rect")

    
    h5(class="mx-3 mt-4 mb-3" v-if="!loadingAnnouncements") Announcements
    b-skeleton(height="40px"  variant="rect" v-else class="mx-3 mt-4 mb-3")
    div(v-if='caregiverName && notificationOn === "false"' class="my-3 mx-3 font-italic text-muted" @click='this.showPermissionModal')
                h6 Notifications are disabled
                    b-icon.mx-2(icon="bell-slash", style="position: relative;")
    div(class="w-100 p-3" v-if="!loadingAnnouncements")
        pinned-announcement(:pinned="pinned" :showModal.sync="showModal"  @update:selectedAnnouncement="showModalAnnouncement")
        b-button(class="go-to-announcement mt-2  w-100" variant="secondary" style="background-color:#8967b3;" @click="$router.push('/announcement')") View All Announcements
    div(v-else-if="loadingAnnouncements" class="d-flex justify-content-center align-items-center " style="width:92%; margin: 0 auto;")
        b-skeleton(height="100px" width="100%" variant="rect")
    b-modal(v-model="showModal" title="Announcement" size="is-large" scrollable)
        div(class="d-flex flex-column w-100")
            div(class="d-flex w-100 justify-content-between mt-2")
                h5 {{selectedAnnouncement?.crb5c_title}}
                p(class="date-announcement") {{new Date(selectedAnnouncement?.createdon).toLocaleDateString()}}
            div(v-html="selectedAnnouncement?.crb5c_body ? JSON.parse(selectedAnnouncement?.crb5c_body) : ''" class="mt-4")
        template(v-slot:modal-footer)
            b-button(class="button is-success" @click="showModal = false") Close 
    h5(class="mx-3 mt-4 mb-3" v-if="!loadingAnnouncements") Quick Actions
    b-skeleton(height="40px"  variant="rect" v-else class="mx-3 mt-4 mb-3")
    div(class="quick-actions-container w-100   my-2 mb-5 px-4 d-flex align-items-center overflow-auto" style="gap: 1rem;" v-if="!loadingAnnouncements && caregiverName")
        quick-action-card(v-for="(action, index) in quickActions" :key="index" :action="action" )   
    div( class="d-flex align-items-center w-100 px-3 overflow-auto" v-else style="gap: 1rem;")
        b-skeleton(height="150px" max-width="150px" width="150px" variant="rect" v-for="action in quickActions" :key="action.name" class="quick-action-card position-relative d-flex flex-column justify-content-center align-items-center p-2 shadow-md" style="gap: 1rem; flex-shrink: 0;" )
    div(class="m-3 d-flex justify-content-center align-items-center p-2 rounded-circle notification-icon shadow" v-if="caregiverName && notificationOn")
        b-icon(icon="bell-slash" size="is-large" v-if='caregiverName && notificationOn === "false"')
        b-icon(icon="bell" size="is-large" v-else-if='caregiverName && notificationOn === "true"')
        

        
                
          
        
</template>

<script>
import PinnedAnnouncement from '@/components/PinnedAnnouncement.vue';
import LottieAnimation from 'lottie-web-vue'
import sunAnimation from "../assets/sunAnimation.json"
import moonAnimation from '../assets/moonAnimation.json'
import HomeCard from "@/components/home/HomeCard.vue";
import HomeList from "@/components/home/HomeList.vue";
import HomeClient from "@/components/home/HomeClient.vue";
import QuickActionCard from '@/components/QuickActionCard.vue';
import consentModal from '@/components/setting/consentPopUp.vue'
import axios from 'axios';
import { mapState } from "vuex";

export default {
    components: {
        LottieAnimation,
        HomeCard,
        HomeList,
        HomeClient,
        QuickActionCard,
        PinnedAnnouncement,
        consentModal
    },
    data() {
        return {
            existingRecord: '',
            consent: [],
            sun: sunAnimation,
            moon: moonAnimation,
            notificationOn: null,
            fetching: true,
            announcementCollapse: true,
            surveystatus: null,
            quickActions: [
                { name: 'Announcement', path: '/announcement', icon: 'megaphone',disabled:false },
                { name: 'Event', path: '/events', icon: 'calendar' },
                { name: 'Past Sessions', path: '/session', icon: 'book',disabled:false },
                { name: 'Contact Us', path: '/contact', icon: 'person', disabled:false},
                {name: 'More Features Coming Soon', path: '/home', icon: 'star', disabled: true},
                // { name: 'Survey', path: '/survey', icon: 'clipboard' },
                // { name: 'Chat', path: '/messaging', icon: 'chat' },
            ],
            pinned: [],
            announcements: [],
            loadingAnnouncements: false,
            selectedAnnouncement: {
                    crb5c_title: "",
                    crb5c_body: "",
                    createdon: "",
                },
            showModal: false,
            existingImage: null,

            
        }
    },
    methods: {
        showModalAnnouncement(announcement){
                // console.log(announcement);
                this.selectedAnnouncement = announcement;
                // console.log(this.selectedAnnouncement);
                this.showModal = true;
            },
        async loadCurrentUser() {
            while (!this.$store.state.currentUser[0]) {
                await new Promise(resolve => setTimeout(resolve, 200));
            }
            const currentUser = this.$store.state.currentUser[0];
            const currentDate = new Date();
            await axios.get(`/api/records/${currentUser.crb5c_client.crb5c_fow_customerid}/${currentUser.crb5c_fow_caregiverid}`)
                .then(async (response) => {
                    const data = response.data;
                    let payload;
                    if (!data.length) {
                        payload = {
                            client: currentUser.crb5c_client.crb5c_fow_customerid,
                            caregiver: currentUser.crb5c_fow_caregiverid,
                            login: currentDate,
                        }
                    }else if(data.length){
                        payload = {
                            recordID: data[0].crb5c_fowmobilerecordsid,
                            login: currentDate,
                        }   
                    }
                    await axios.post(`/api/records/${currentUser.crb5c_fow_caregiverid}`, payload)
                     .catch((errordata) => {
                        console.error('Error post data', errordata)
                     })
                })
                .catch((errordata) => {
                    console.error('Error fetching data', errordata)
                });            
        },
        async checkNotificationPermission(){
            try {
                const optedIn =  this.$OneSignal.User.PushSubscription.optedIn;
                const permission = this.$OneSignal.Notifications.permissionNative;
                // console.log('optedIn',optedIn)
                // console.log('permission',permission)
                // console.log('this.$store.state.currentUser[0]',this.$store.state.currentUser[0])
                const notificationStatus = optedIn && permission;
                localStorage.setItem("notificationEnabled", notificationStatus);

                if (this.$store.state.currentUser[0]) {
                    if(notificationStatus){
                        this.loginOnesignal();
                    }else{
                        this.showPermissionModal();
                    }
                }
            } catch (error) {
                console.error("Error checking for permission",error);
            }
        },
        showPermissionModal() {
            this.$bvModal.show('modalPermission');
        },
        requestPermission() {
            this.$bvModal.hide('modalPermission');
            this.$OneSignal.Notifications.requestPermission()
                .then(result => {
                    console.log(result);
                    localStorage.setItem("notificationEnabled", true);
                    this.notificationOn = true;
                    this.loginOnesignal();
                }).catch(err => {
                    console.error('Error requesting permission',err);
                    localStorage.setItem("notificationEnabled", false);
                    this.notificationOn = false;
                });
        },
        async loginOnesignal(){
            try {
                const phone = this.$store.state.currentUser[0].crb5c_contactnumbermobile;
                if (!phone) {
                    alert('Please re-set your phone number with the administator');
                    console.error('No phone number');
                    return;
                }
                await this.$OneSignal.login(`${phone}`);
                // console.log('Logged-in with OneSignal');
            } catch (error) {
                console.error('Error logging in OneSignal',error);
            }
        },
        redirectSurveyPage() {
            this.$router.push(`/survey`)
        },
        async reloadImage() {
            if (!this.validImageUrl) {
                // console.log('fetching image');
                await this.$store.dispatch('fetchImage');
            }
        },
    },
    computed: {
        loadingImage: {
            get() {
                return this.$store.state.loadingImage;
            },
            set(value) {
                this.$store.commit('setLoadingImage', value);
            }
        },
        validImageUrl() {
            return this.$store.state.groupPhoto;
        },
        caregiverName() {
            return this.$store.state?.caregiver?.name
        },
        timeOfDayGreet() {
            let hour = this.$root.dayjs().hour();
            return hour < 12 ? 'Morning' : hour < 13 ? 'Afternoon' : 'Evening';
        },
        ...mapState({
            caregiver: state => state.caregiver,
        }),
        lastAnnouncements() {
            let cloned = JSON.parse(JSON.stringify(this.$root.announcements));
            const ann = cloned?.sort((a, b) => {
                if (a.dateUpdated >= b.dateUpdated) {
                    return 1;
                } else {
                    return -1;
                }
            }).slice()
            return ann.reverse().slice(0, 3);
        },
        currentClientName() {
            return this.$store.state.cli_name;
        },
        currentClientId() {
            return this.$store.state.cli_id
        },
        currentCaregiverId() {
            return this.$store.state.currentUser[0].crb5c_fow_caregiverid
        }
    },
    async created() {
        // FIX: only run when after login, if the token exist, dont run this.
        // await this.$root.loginAndFetch();
    },
    async mounted() {
         
        this.loadingAnnouncements = true;
       
        this.$nextTick(() => {
            const anim = this.$refs.anim?.anim;
            if (anim) {
                setTimeout(() => {
                    anim.goToAndPlay(150, true);
                }, 500);
            } else {
                console.error('Lottie animation not found');
            }
        });
        
        await this.loadCurrentUser();
        await this.reloadImage();
        try{
            if (this.$root.currentUser[0] && this.$root.currentUser[0]?.nextSession){
            const sessionIds = this.$root.currentUser[0]?.nextSession?.map(ses => ses.session?._crb5c_sessionscheduler_value) || [];
            const {data} = await axios.get(`/api/announcements/v2`,{
                params: { groupID: sessionIds }
            });
            let {pinned,general} = data;
            this.announcements = general;
            this.pinned = pinned;
            }
        }
        catch(err){
            console.log(err);               
        }
        finally{
            this.loadingAnnouncements = false;
            this.loadingImage = false;
        }

        this.notificationOn = localStorage.getItem("notificationEnabled");
        // console.log('notificationOn',this.notificationOn)

        if(this.notificationOn === null || this.notificationOn === 'false'){
            // console.log('Notifcation is disabled')
            this.showPermissionModal();
        }else{
            // console.log('Notifcation is enabled')
            await this.checkNotificationPermission();
        }
 
        const response = await axios.get(`/api/records/${this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid}`)
        if (!response.data || !response.data.length ) {
            this.$bvModal.show('modalConsent');
        }

    },
    inject: ["users", "announcements", "reminders"],
};
</script>

<style lang="scss" scoped>
.main-container {
    height: 90vh;
    width: 100%;
    overflow-y: scroll;

    .greeting-container {
        height: 15vh;
    }
    .quick-actions-container{
        width: 100%;
        
        
    }

}
.loading{
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notification-icon{
    background-color: white;
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    * {
        color: #8967b3;
        
    }
}
</style>
