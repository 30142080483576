<template lang="pug">
.mainBody(v-if="!userLoading")
  b-container
    b-row.my-3(style="gap: 1rem;")
      //- b-col.col-12
      //-   p.h5 Past Session
      b-col.col-12
        b-row
          b-col.col-12
            b-input-group
              b-form-input.m-auto.border-dark(
                type="month",
                v-model="getSelectedMonth",
              )
              b-input-group-append
                b-button(style="background-color: #8967b3;" @click="setSortMonth")
                  b-icon(icon="filter-right")

    //- Client List
    b-overlay(:show="stateFetchingSession.isFetchingSession" variant="transparent") 
      p.h6.my-auto(v-if="!sortedPastSessions.length") No past sessions found for this month
      session-list(
        v-for="(s, key) in sortedPastSessions",
        :sessionid="sessionid(s)",
        :sessionname="sessionname(s)",
        :customername="$store.state.cli_name",
        :time="sessiondate(s)"
      )
div(v-else class="d-flex w-100 flex-column p-2")
  b-skeleton(height="5vh" width="100%" class="my-2")
  b-skeleton(height="5vh" width="100%" class="my-2")
  b-skeleton(height="10vh" width="100%" class="my-2")
</template>

<script>
// @ is an alias to /src
import SessionList from "@/components/session/SessionList.vue";
import dayjs from "dayjs";
import BackButton from "@/components/BackButton.vue";
// import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js"; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Singapore");

export default {
  data() {
    return {
      // for sort
      currentMonth: dayjs().month(),
      selectedMonth: new Date().toISOString().slice(0, 7),
      pastsessionsArray: [],
      fetching: false,
      isSortAscending: false,
      userLoading: false,
    };
  },
  components: {
    SessionList,
    BackButton,
  },
  methods: {
    async loadCurrentUser() {
            while (!this.$store.state.currentUser[0]) {
                await new Promise(resolve => setTimeout(resolve, 200));
            }
        },
    ...mapMutations(["setSelectedDate", "setSelectedDate"]),
    ...mapActions(["fetchSelectedMonth"]),
    sessionid(s){
      return s?.details?.session?.crb5c_fow_sessionid || null;
    },
    sessionname(s){
      return s?.details?.session?.crb5c_sessionname || null;
    },
    sessiondate(s){
      const sessiondate = s?.details?.session?.crb5c_date;
      const date =  sessiondate ? this.$root.dayjs(sessiondate).format('DD/MM/YYYY') : null;
      const type = sessiondate ? this.$root.dayjs(sessiondate).format('A') === 'AM' ? 'Morning' : 'Afternoon' : null;
       
      return {date,type};
    },
    sortItemInMonthDescending(listData, isSortAscending){
      const copyListData = [...listData];

      if(isSortAscending){
        copyListData.sort((a, b) => {
          return new Date(b.details?.session.crb5c_date) - new Date(a.details?.session.crb5c_date);
        });

        return copyListData;
      }

      copyListData.sort((a, b) => {
        return new Date(a.details?.session.crb5c_date) - new Date(b.details?.session.crb5c_date);
      });

      return copyListData;

    },
    setSortMonth(){
      this.isSortAscending = !this.isSortAscending; 
    },
  },



  async mounted() {
    this.userLoading = true;
    await this.loadCurrentUser();
    const lastMonth = dayjs.tz().subtract(1, "month");
    this.selectedMonth = lastMonth.format("YYYY-MM");

    await this.$root.loginAndFetch();
    this.userLoading = false;
  },
  computed: {
    ...mapState(["stateFetchingSession"]),
    getSelectedMonth: {
      get(){
        return this.stateFetchingSession.selectedMonth;
      },
      set(newValue){
        this.setSelectedDate({ selectedMonth: newValue })
      }
    },
    sortedPastSessions() {
      const isSortAscending = this.isSortAscending;
      return this.sortItemInMonthDescending(this.$store.state.pastsessions || [], isSortAscending);
    },
  },
  watch: {
    async getSelectedMonth(value){
      try {
        await this.fetchSelectedMonth({ selectedMonth: value, });
        
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$base-color-purple: #50276b;
$base-color-yellow: #ffcd06;
.blockButton {
  background-color: transparent;
  color: $base-color-purple;
  border: 2px solid $base-color-purple;
}
</style>