<template lang="pug">
    b-modal#modalConsent(centered, title="Media and Publicity" hide-footer)
                ol.my-3
                    li.my-2 By entering into Family of Wisdom Centre, photographs of activity with clients' presence could be taken during any for the session.
                    li.my-1 Family of Wisdom is authorised to use any of these photographs with or without client's name for any lawful purpose including, though not exhaustive, for purposes such as publicity, illustration and web content.
                b-form-group() 
                    b-form-checkbox.my-2(:disabled="!!existingRecord" v-for="item in consentBox" :value="item.value" :key="item.value" v-model="consentCheck") {{ item.text }}
                div(class="d-flex justify-content-center").mt-4
                    b-button(:disabled="!!existingRecord" class="px-5 shadow" variant="success" class="mt-3" size="md" @click="publicityConsent")
                        | Confirm    
</template>
    
<script>
/* eslint-disable */
import axios from 'axios';
import { mapState } from "vuex";
export default {
    props: {
           consent: {
                type: Array,
                required: true
           },
           existingRecord: {
                type: String,
                required: false
           } 
        },
    data() {
        
        return {
            consentCheck: [],
            consentBox: [
            { text: 'I willingly authorize the Centre to use any films, video, photos taken whilst attending the sessions. This may be for the purpose of educating others on understanding and managing a person with dementia.',value:'consent-1'},
            { text: 'I also give my permission to the publication of such photos or video for the purpose of PUBLIC education, with the understanding that he/she may be identified by his/her first name.',value:'consent-2'}
        ],
        };
    },
    async mounted(){
        this.consentCheck = this.consent;
    },
    methods: {
        async publicityConsent(){
            const data = {
                client: this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid,
                caregiver: this.$root.currentUser[0].crb5c_fow_caregiverid,
                content:{
                    consent1: this.consentCheck.includes('consent-1') ? 1 : 0,
                    consent2: this.consentCheck.includes('consent-2') ? 1 : 0,
                    isDownloaded: 1,
                },
                recordId: this.existingRecord ? this.existingRecord : '',
                

            }
            try{
                const response = await axios.post("/api/records", data);
                if(response){
                    this.$bvToast.toast('Consent Updated', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    this.$bvModal.hide('modalConsent');
                }
            }
            catch(err){
                console.log(err);
                this.$bvToast.toast('Failed to update consent', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
            }
            
        },
    },
    computed: {
        ...mapState({
        caregiver: state => state.caregiver,
    }),
    isExisting(){
        return 
    }
    }

};
</script>

<style lang="scss" scoped>
</style>
