<template lang="pug">
div
  b-alert.fixed-top(v-model="$root.installReady" variant="dark" fade)
    .d-flex.align-items-center.justify-content-between
      b-img.mr-3(src="img/icons/android-chrome-maskable-512x512.png" style="max-height:80px;" thumbnail)
      div.font-weight-bold
        p.mb-1 For the best experience, install the app on your phone.
        p.small.mb-0
          a(href="#" @click="$root.installClicked=true") Continue in mobile website instead
      b-button.d-flex.align-items-center.justify-content-center.ml-2.align-self-stretch.flex-wrap(@click="$root.installApp" variant="warning"  size="sm")  
        //b-icon-download
        span.font-weight-bold Get App
  b-modal(v-model="$root.noInstallprompt" title="Install DSG FOW App" hide-footer)
    .d-flex.align-items-center
      b-img.mr-3(src="img/icons/android-chrome-maskable-512x512.png" style="max-height:80px;" thumbnail)
      h6 For the best experience, install the app on your phone.
    hr
    p.lead Instructions for iPhone users:
    ol
      li.mb-1 Click on <b-button variant="outline-secondary" size="sm" style="vertical-align: middle;" class="m-0  py-1"><icon icon="material-symbols:ios-share-rounded"/></b-button> at the bottom
      li.mb-1 Scroll down and click on<br/><b-button variant="outline-secondary" size="sm" style="vertical-align: middle;" class="m-0 py-1">Add to Home Screen <icon icon="material-symbols:add-box-outline"/></b-button>
      li.mb-1 Confirm and press add
      //b-accordion-item(title="Instructions for Firefox(Android)")
        ol
          li Click on <b-button variant="outline-secondary" size="sm" style="vertical-align: middle;" class="m-0  py-1"><Icon icon="material-symbols:more-vert"/></b-button> at the top right
          li Scroll down and click on <br/><b-button variant="outline-secondary" size="sm" style="vertical-align: middle;" class="m-0 py-1"><firefox-icon alt="installicon" style="height:15px; width:auto;" class="me-2"/>Install</b-button>
          li Confirm and press add
    hr
    b-button(block style="width:100%;" variant="success" @click="$root.noInstallprompt=false") Nevermind, let me use the mobile website instead
  b-overlay(:show="$root.changingClient")
    nav-bar(v-if="!hideHeaderPages.includes($route.path)")
    //- header-side(v-if="!hideHeaderPages.includes($route.path)")
    transition(name="fade" mode="out-in")
      router-view
</template>

<script>
import HeaderSide from '@/components/HeaderSide.vue'
import { Icon } from '@iconify/vue2';
import NavBar from './components/NavbarV2.vue';

export default {
  data() {
    return {
      hideHeaderPages: ['/welcome', '/login']
    }
  },
  components: {
    HeaderSide,
    Icon,
    NavBar
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    caregiver(){
      return this.$store.state.caregiver;
    },
    pastsessions() {
      return this.$store.state.pastsessions;
    },
    announcements() {
      return this.$store.state.announcements;
    },
    reminders() {
      return this.$store.state.reminders;
    },
    invoice_lists() {
      return this.$store.state.invoice_lists;
    },
    invoices() {
      return this.$store.state.invoices;
    },
  },
  provide() {
    return {
      users: this.users,
      pastsessions: this.pastsessions,
      announcements: this.announcements,
      reminders: this.reminders,
      invoice_lists: this.invoice_lists,
      invoices: this.invoices,
    };
  },
  async mounted() {},
};
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
}

$base-color-purple: #50276b;
$base-color-yellow: #ffcd06;
* {
  box-sizing: border-box;
}
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


</style>
