<template lang="pug">
b-modal(v-model="showModal", size="lg", centered )
    template(#modal-header)
        h6 Warning! 
    div
        p.text-danger You are proceeding to register for this event without a caregiver. You cannot edit once the registration is submitted. Are you sure you want to proceed?
    template(#modal-footer)
        b-button(@click="showModal = false") Cancel
        b-button(@click="closeModal" variant="warning") I understand, let me proceed.


</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            showModal: {
                get() {
                    return this.show
                },
                set(value) {
                    this.$emit('update:show', value)
                }
            }
        },
        methods:{
            closeModal(){
                this.$emit('showConfirmation')
                this.showModal = false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>