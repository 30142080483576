<template lang="pug">
div.my-4
    div(class="d-flex flex-column" v-b-modal="'modal' + id")
        p(class="h6 p-2 font-weight-bold text-center rounded bg-warning shadow") {{action}}
    b-modal(:id="'modal' + id" :title="action" header-bg-variant="warning" centered)
        .d-flex
            p.font-weight-bold Remark: 
            p.ml-2 {{remark}}
        p.font-weight-bold Pictures from session:
        div(class="imageActivityBody")
            img(class="w-100 my-1" :src="baking")
        template(#modal-footer="{cancel}")
            b-button(size="sm" @click="cancel()") Close
</template>

<script>
import baking from '@/assets/baking.png';

export default {
    data() {
        return {
            baking
        }
    },
    props: {
        id: {
            type: Number
        },
        action: {
            type: String,
        },
        remark: {
            type: String,
        },
        imgsrc:{
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;
$base-color-yellow :#FFCD06;

.profileImageBody {
    width: 150px;
    height: 150px;
}
</style>