<template lang="pug">
div(class="quick-action-card position-relative d-flex flex-column justify-content-center align-items-center p-2 shadow-md" style="gap: 1rem; flex-shrink: 0;"
    @click="goTo(action)"
    
)
    div(class="floating-icon position-absolute d-flex justify-content-center align-items-center")
        b-icon(:icon="action.icon" size="lg")
    h6.text-center.text-wrap.w-100 {{action.name}}
</template>

<script>
export default {
    props: {
        action: {
            type: Object,
            required: true
        }
    },
    methods: {
        goTo(action) {
            if(action.disabled) return;

            this.$router.replace(action.path);
        }
    }
}
</script>

<style lang="scss" scoped>
.quick-action-card {
    margin-top: 1.4rem;
    height: 150px;
    min-width: 150px; 
    max-width: 150px;
    position: relative;
    border-radius: 10px;
    background-color: #8967B3;
    color: white;
}

.floating-icon {
    width: 40px;
    height: 40px;
    background-color: #C68FE6;
    border-radius: 50%;
    top: -20px; 
    left: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    z-index: 1;
    // color: white;
}

h6 {
    margin-top: 1rem; 
    text-align: center;
}
</style>
