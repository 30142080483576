<template lang="pug">
div(class="mainBody")
    img(src="../assets/DSGlogo.png")
    div(class="headerText") 
        h1(v-if="step===1" class="DSGName") Family of Wisdom
        h1(v-else-if="step===2" class="DSGName") Verify Phone Number
        p(v-if="step === 1") Welcome! Login with your Phone  to use the app
        p(v-else-if="step === 2") Welcome! Enter the OTP sent to your phone to login
    div(class="formBody")
        div(class="form" id="form" )
            div(v-if="step === 1")
                b-form-group(class="my-4" id="phoneLabel" label="Enter Your Phone Number:" label-for="phoneInput")
                    VuePhoneNumberInput(:default-country-code="'SG'" @update="phoneUpdate" :preferred-countries="['SG', 'MY']" v-model="phoneRaw" @keyup.enter.native="sendOTP")
            div(v-else-if="step === 2")
                b-form-group(class="my-4" id="passwordLabel"  label-for="passwordInput" :label="`OTP Sent to your Mobile: ${countryCode}-${phone}`")
                    .token-inputs
                        b-form-input.inputBox.p-3(v-for="(value, index) in token", placeholder="-" :key="index",inputmode="numeric" type="text", maxlength="1", v-model="token[index]", @input="focusNext(index, $event)", @keydown="handleBackspace(index, $event)", ref="tokenInputs", required :class="{filled: value.length > 0}")
            
    button(v-if="step===1" class="submitBtn" @click="sendOTP" :disabled="!phoneValid || sendingOtp" :class="{disabled: !phoneValid || sendingOtp}") {{sendingOtp ? 'Sending OTP...' : 'Send OTP'}}
    button(v-else-if="step===2" class="submitBtn" @click="wrapLogin" :disabled="!ifOTPComplete() || loggingIn" :class="{disabled: !ifOTPComplete() || loggingIn}") {{loggingIn ? 'Verifying OTP...' : 'Verify OTP'}}
    p(v-if="step===2" class="wrongPhone") Wrong Phone Number?
        button(v-if="step===2" class="wrongButton" @click="step=1") Go Back
    div(class="issues-button d-flex align-items-center")
        b-card-text.my-2 Having Issues With the App?
        a(href="https://wa.me/message/WUVWRHF67KYTE1" target="_blank") Report Here
    b-modal#helpcentre(centered, title="Report issue" hide-footer)
        b-card-text.my-2 We cannot find your phone number as one of the registered caregivers, leave us a message by clicking below and we will get back to you as soon as possible!
        .d-flex.justify-content-center
            b-button.my-2(variant="warning" href="https://wa.me/message/WUVWRHF67KYTE1" target="_blank") Click Here

        
    
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
export default {
    components: {
        VuePhoneNumberInput
    },
    data() {
        return {
            step: 1,
            phoneRaw: "",
            phone: "",
            token: Array(6).fill(""),
            error: "",
            phoneValid: false,
            countryCode: "",
            sendingOtp: false,
            loggingIn: false
        };
    },
    methods: {
        ifOTPComplete() {
            return this.token.every((value) => value.length > 0);
        },
        
        handleBackspace(index, event) {
            if (event.key === 'Backspace' && index > 0 && this.token[index] === '') {
                this.$refs.tokenInputs[index].focus();
            }
        },
        focusNext(index, event) {
            if (event.inputType === 'deleteContentBackward') return;

            if (index < this.token.length - 1) {
                this.$refs.tokenInputs[index + 1].focus();
            }
        },
        phoneUpdate(e) {
            this.countryCode = e.countryCallingCode

            if (e.isValid) {
                this.phoneValid = true;
                this.phone = e.nationalNumber;
                return;
            }

            this.phoneValid = false;
        },
        async wrapLogin() {
            this.loggingIn = true;
            const tokenToVerify = this.token.join('');
            let result = await this.$root.verifyOTP(this.phone, tokenToVerify, this.countryCode);
            if (!result) {
                this.loggingIn = false;
            }
        },
        async sendOTP() {
            if (!this.phoneValid || this.sendingOtp) {
                return;
            }

            this.sendingOtp = true;
            try {
                const { data } = await this.$root.axios.get(`/sms?nums=${this.phone}&country=${this.countryCode}`);
                if (data?.success) {
                    this.sendingOtp = false;
                    this.step = 2;
                    return;
                }

                this.$bvModal.show('helpcentre');
                // alert('We cannot find your phone number as one of the registered caregivers. Please contact Eunice at eunice.tan@dementia.org.sg.')
                this.sendingOtp = false;
                return;
            } catch (e) {
                this.error = e;
                alert("Error: " + JSON.stringify(this.error));
                this.sendingOtp = false;
                this.loggingIn = false;
            }
        },
    },

}
</script>

<style lang="sass" scoped>
.mainBody
    display: flex
    flex-direction: column
    padding: 5% 5%
    height: 100vh
    width: 40%
    margin: auto
    transition: all 0.5s
    @media (max-width: 1024px)
        width: 100%
        background-color: #f5f5f5
    img
        width: 120px
        height: 50px
    .headerText
        display: flex
        flex-direction: column
        margin: 5% 0
        .DSGName
            font-size: 20px
            font-weight: bold
            margin: 0
        p 
            font-size: 12px
            margin: 0
            color: #50276b
    .submitBtn
        background-color: #8967b3
        color: white
        border: 1px solid #8967b3
        border-radius: 5px
        padding: 8px
        transition: all 0.5s
    .disabled
        background-color: #ccc
        color: #666
        border: 1px solid #ccc
.issues-button
    position: fixed
    bottom: 20px
    left: 50%
    transform: translateX(-50%)
    width: 100%
    padding:0 10px
    gap: 10px


#passwordLabel,#phoneLabel
    font-weight: 500
    font-size: 14px
        
#MazPhoneNumberInput 

    .country-selector 
        background-color: #50276b
        color: white
        border: 1px solid #50276b
        border-radius: 5px
        padding: 5px
        margin: 5px
.wrongPhone
    font-size: 12px
    
    margin: 5px
    .wrongButton
        background-color: transparent
        border: none
        color: #50276b
        font-size: 12px
        margin: 5px
        cursor: pointer
    
.token-inputs 
  display: flex
  gap: 10px
  
  


.inputBox 
  width: 15%
  height: 50px
  text-align: center
  
.filled
    border: 1px solid #8967b3
    border-radius: 5px
    background-color: #8967b3
    color: white
    font-weight: bold
    font-size: 20px
    padding: 10px
    text-align: center
    width: 15%
    height: 50px
    text-align: center
    cursor: pointer
    &:focus
        outline: none
        border: 1px solid #50276b
        border-radius: 5px
        background-color: #50276b
        color: white
        font-weight: bold
        font-size: 20px
        padding: 10px
        text-align: center
        width: 15%
        height: 50px
        text-align: center
        cursor: pointer
    
</style>