<template lang="pug">
div.d-flex.justify-content-start
    div(class="receiver-image d-flex flex-column p-2 rounded")
        img(class="" 
            src="@/assets/newDsgLogo.png"
        alt="user photo" width="30" height="30")
    div.receiverBody
        p(class="message mb-2") {{ message }}
        div(class="d-flex flex-column receiver-info")
            p {{ name }}
            div.d-flex.justify-content-end(class="time-date")
                p {{ time }}
    
</template>

<script>
export default {
    props: {
        id: {
            type: String,
        },
        name: {
            type: String,
        },
        message: {
            type: String,
        },
        time: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>


.receiverBody {
    background-color: gray;
    border-radius: 10px;
    color: white;
    width: 60%;
    max-width: 75%;
    height: auto;
    margin: 0.2rem;
    padding: 10px;
    .message{
        margin: 0;
        font-size: 1rem;
        white-space: pre-wrap;
        padding: 1px;
    }
    .receiver-info{
        color: #f1f1f150;
        align-items: start;

        p{
            margin: 0;
            font-size: 0.8rem;
            
        }
    }
    


}
.receiver-image{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
       
    }

</style>