<template lang="pug">
b-modal(v-model="showModal", size="lg", centered no-close-on-backdrop)
    template(#modal-header="{ close }")
        h5.m-0 Confirmation of your submission
    .container
        lottie-animation(:animation-data="successLottie" :autoPlay="true" :loop="false" :speed="1" ref="anim" class="w-full")
        p Your submission has been successfully submitted
    template(#modal-footer)
        b-button(@click="showModal = false" :disabled="loading" variant="primary") 
            b-spinner(v-if="loading" small)
            span(v-else) Close
</template>
<script>
import LottieAnimation from "lottie-web-vue";
import successAnimation from '@/assets/success.json';
export default {
  components: {
    LottieAnimation,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    reloadFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      successLottie:successAnimation
    };
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
  watch: {
    show: {
      handler: async function (val) {
        this.showModal = val;
        this.loading = true;
        await this.reloadFunction();
        this.loading = false;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
