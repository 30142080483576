<template lang="pug">
div(class="" style="height: 80vh;")
    div.d-flex.p-2.align-items-center(style="height: 10%;")
        BackButton
    b-container.d-flex.flex-column#chat-container(ref="chat-container" style="height: 95%; max-height: 95%; overflow: auto;")
        div(
            class="messageBody d-flex flex-column my-2" 
            v-for="(msg, i) in chatMessages"
            :key="msg.id"
          )
            receiver-text(v-if="msg.name !== $root.myParticipantId" :name="`FOW Staff ${msg.admin ? ' (' + msg.admin + ')' : ''}`" :message="msg.message" :time="msg.time")
            sender-text(v-else name="Caregiver" :message="msg.message" :time="msg.time")
    div.d-flex.p-2.align-items-center(style="height: 10%;")
        b-form-input.my-auto.mr-2.p-4(class="inputBox" v-model="message" placeholder="Type your messages here" :disabled="sending" @keyup.enter="send" ref="chat-input-box")
        p.h3.my-auto()(class="arrow-right-circle" @click="send" :disabled="sending") 
            b-icon.iconBody.my-auto(icon="arrow-right"  v-if="!sending")
            b-spinner.iconBody.my-auto(variant="primary" type="grow" small v-else)

</template>

<script>
// @ is an alias to /src
import SenderText from "@/components/messaging/SenderText.vue";
import ReceiverText from "@/components/messaging/ReceiverText.vue";
import AutomatedText from "@/components/messaging/AutomatedText.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import isToday from "dayjs/plugin/isToday.js";
import BackButton from "@/components/BackButton.vue";

const defaultTimezone = "Asia/Singapore";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(defaultTimezone);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);

export default {
  name: "MessagingPage",
  async mounted() {
    await this.$root.loginAndFetch();
    this.scrollToBottomChat();
  },
  data() {
    return {
      message: "",
      sending: false
    };
  },
  watch: {
    chatMessages: {
      handler(newArr, oldArr) {
        if (newArr.length > oldArr.length) {
          this.scrollToBottomChat();
          this.resetChat();
        }
      },
      deep: true,
    },
  },
  computed: {
    chatMessages() {
      return (
        this.$root.chatMessages.map((m) => {

          const timeFormat = dayjs.tz(m.timestamp).isToday() ? dayjs.tz(m.timestamp).format("hh:mm A") : dayjs.tz(m.timestamp).format("DD/MM/YYYY hh:mm A")

          return {
            id: m.sid,
            name: m.author,
            message: m.body,
            admin: m.attributes?.admin,
            time: timeFormat,
          };
        }) || []
      );
    },
  },
  components: {
    SenderText,
    ReceiverText,
    AutomatedText,
    BackButton
  },
  methods: {
    scrollToBottomChat() {
      const chatContainer = this.$refs["chat-container"];
      if (chatContainer) {
        this.$nextTick(() => {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        });
      }
    },
    containsOnlySpaces(str) {
      return str.trim().length === 0;
    },
    async send() {
      try {
        if (this.sending) {
          return;
        }
  
        this.sending = true;
        const msg = this.message;
        
        if(this.containsOnlySpaces(msg)){
          this.resetChat();
          return;
        }
  
        const pl = {
          chatRoomId: this.$root.chatRoomId,
          senderId: this.$root.myParticipantId,
          msg,
        }
  
        // eslint-disable-next-line no-unused-vars
        let { data } = await this.$root.axios.post("/chat", pl);
  
        // console.log(data);
        
      } catch (error) {
        this.makeToast("danger", "Chat", "Failed to send message");
        this.resetChat();
      }
    },
    resetChat(){
      this.sending = false;
      this.message = "";
      let chatInputBox = this.$refs['chat-input-box'];
      if (chatInputBox) {
        this.$nextTick(() => {
          chatInputBox.focus();
        })
      }

    },
    makeToast(variant, title, message) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true
      })
    }
  },
};
</script>

<style lang="scss" scoped>
$base-color-purple: #50276b;
$base-color-yellow: #ffcd06;
.title {
  background-color: #545454;
  color: white;
}

.messageBody {
  color: white;
}

// .inputBody {
//   height: 10%;
// }
.inputBox {
  background-color: #b5b5b5;
  border-radius: 5px;
}

.iconBody {
  cursor: pointer;
  border-radius: 50px;
}

.iconBody:hover {
  background-color: $base-color-purple;
  color: white;
}
.arrow-right-circle{
  background-color: #50276b;
  color: white;
  padding: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
</style>
