<template lang="pug">
p.h6(@click="notif(3000)" v-if="name && !loading") Next Upcoming Session:
    b-card(v-if="name && !loading" class="shadow cardBody my-4" text-variant="white" :title="name" class="text-center")
        div.d-flex.border-top.pt-4
            b-card-text Client:
            b-card-text.ml-2.font-weight-bold {{client}}
        div.d-flex
            b-card-text Time:
            b-card-text.ml-2.font-weight-bold {{time}}
        div.d-flex
            b-card-text Date:
            b-card-text.ml-2.font-weight-bold {{date}}
    b-card(v-else-if="!name && loading" class="shadow cardBody my-4" text-variant="white" title="Checking..." class="text-center")
        div.d-flex.border-top.pt-4
            b-card-text.ml-2.font-weight-bold  
    b-card(v-else-if="!name && !loading" class="shadow cardBody my-4" text-variant="white" title="No Upcoming Session found yet. Please check tomorrow!" class="text-center")
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
        },
        name: {
            type: String,
        },
        time: {
            type: String,
        },
        date: {
            type: String,
        },
        client: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;
.cardBody{
    background-color:#50276B;
    border-radius: 20px;
}
</style>