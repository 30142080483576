<template lang="pug">
b-button(@click="$router.push('/home')"  class="back-button my-2 p-2" size="sm" block)
    b-icon(icon="house-fill" class="mr-2")
    span Home
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>