<template lang="pug">
div
    .quarter-circle-top-left
    b-container(class='mainBody fluid d-flex flex-column justify-content-center align-items-center')
        img(class="img-fluid rounded-circle w-50" src="@/assets/android-chrome-maskable-512x512.png")
        h6(class="text-center mt-4") Welcome to Family of Wisdom
        
        
    .quarter-circle-bottom-right(style="z-index: -1;")

    //- b-modal#modalPermission(centered, title="Enable notification")
    //-     b-button(class="proceedButton mt-5 p-3 font-weight-bold shadow" style="z-index: 5; position: relative;" size="lg" @click="askAndProceed")
    //-             |  Give Permission 
                //- b-icon(class="ml-2" icon="arrow-right")

</template>

<script>
export default {
    async mounted(){
        const SECOND = 1000;
        await new Promise(resolve => setTimeout(resolve, SECOND * 2));

        if (this.isTokenInsideLocalStorage()) {
            this.$router.replace({ path: '/home' });
            return;
        }else{
            this.$router.replace({ path: '/login' });
        }
        
        // if (Notification.permission === 'default' || Notification.permission === 'denied') {
        //     this.$bvModal.show('modalPermission');
        // }else if (Notification.permission === 'default' || !this.isTokenInsideLocalStorage()) {
        //     this.$bvModal.show('modalPermission');
        // }else if (Notification.permission === 'default' || this.isTokenInsideLocalStorage()) {
        //     this.$router.push({ path: '/home' });
        // }

    },
    methods: {
        // async getCMSData(){
        //     try {
        //         const { data } = await this.$root.axios.get(`/fow_cms`);
        //         console.log("CMS data: ", data);

        //     } catch (error) {
        //         console.error(error);
        //     }
        // },
        // askAndProceed(){
        //     function checkNotificationPromise() {
        //         return new Promise((resolve) => {
        //             try {
        //                 Notification.requestPermission().then(result => {
        //                     if (result === "granted") {
        //                         console.log("Notification permission granted");
        //                         resolve(true);
        //                     } else if (result === "denied") {
        //                         console.error("Notification permission denied");
        //                         resolve(false);
        //                     } else {
        //                         resolve(false);
        //                     }
        //                 }).catch(err => {
        //                     console.error("Notification request failed", err);
        //                     resolve(false);
        //                 });
        //             } catch (e) {
        //                 console.warn("Notification.requestPermission did not return a promise", e);
        //                 Notification.requestPermission(function(result) {
        //                     resolve(result === "granted");
        //                 });
        //             }
        //         });
        //     }
                
            
        // checkNotificationPromise().then(isGranted => {
        //     if (isGranted) {
        //         if (this.isTokenInsideLocalStorage()) {
        //             this.$router.push({ path: '/home' });
        //             return;
        //         }
        //         this.$router.push({ path: '/login' });
        //     }
        //     });
            // this.$router.push({path: '/home'});
        // },

        isTokenInsideLocalStorage(){
            const token = localStorage.getItem("token");
            const phone = localStorage.getItem("phone");
            const country = localStorage.getItem("country");

            if(token || phone || country){
                return true;
            }else{
                return false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;
$base-color-yellow :#8967b3;
.mainBody{
    height:100vh;
    width:100%;
    color: $base-color-purple;
}
.imgBody img{
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    max-height: 500px;
}

.proceedButton{
    background-color:$base-color-purple;
    border-radius: 10px;
}

.quarter-circle-top-left {
    position: absolute;
    top:0;
    left: 0;
    width: 150px;
    height: 150px;
    background: $base-color-yellow;
    border-radius: 0 0 150px 0;
    -moz-border-radius: 0 0 150px 0;
    -webkit-border-radius: 0 0 150px 0;
}

.quarter-circle-bottom-right {
    position:absolute;
    bottom:0;
    right:0;
    width: 150px;
    height: 150px;
    background: $base-color-yellow;
    border-radius: 150px 0 0 0;
    -moz-border-radius: 150px 0 0 0;
    -webkit-border-radius: 150px 0 0 0;
}
</style>