<template lang="pug">
b-card
    div(class="d-flex align-items-center" style="gap: 10px;")
        b-card-title {{ event.crb5c_eventname }}
    div(class="d-flex align-items-start mt-2" style="gap: 10px;")
        div(class="badge" :class="getEventStatus(event).class")
            span {{ getEventStatus(event).text }} 
        b-card-text.badge(:class="{ 'badge-primary': event.submitted && !event.notAttending, 'badge-danger': !event.submitted || event.notAttending, 'badge-success': event.submitted && !event.notAttending}") {{ event.notAttending ? "Not Attending" : event.submitted ? "Attending" : "Not Responded Yet" }}
    b-card-text.mt-2(v-html="truncateText(event.jsonData.description, 400)")
    hr
    b-card-text(v-if="!event.jsonData.isSingleDay && event.jsonData.multipleEvents.length >0 ")   Date: {{ new Date(event.crb5c_date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }} To {{ new Date(event.jsonData.multipleEvents[event.jsonData.multipleEvents.length - 1].date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
    b-card-text(v-else) Date: {{ new Date(event.crb5c_date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
    span(v-if="event?.jsonData?.isSingleDay") Time: {{event?.jsonData?.isSingleDay && typeof event?.jsonData?.singleDay?.time === 'object' ? `${event?.jsonData?.singleDay?.time?.hh}:${event?.jsonData?.singleDay?.time?.mm} ${event?.jsonData?.singleDay?.time?.A}` : convertTo12HourFormat(event?.jsonData?.singleDay?.time)}}    
    b-button.w-100.mt-2(@click="$emit('goToDetails', event.crb5c_foweventmanagementid)" variant="primary" v-if="checkExpiry(event)") View Details
    span(v-else) Event has passed
</template>


<script>
export default {
    props: {
        event: Object
    },
    methods: {
        lastDayOfEvent(event) {
            if (event?.jsonData?.isSingleDay) {
                return new Date(event.crb5c_date);
            } else {
                return new Date(event.jsonData.multipleEvents[event.jsonData.multipleEvents.length - 1].date);
            }
        },
        onGoingEvent(event) {
            return new Date(event.crb5c_date) <= new Date() && this.lastDayOfEvent(event) >= new Date();
        },
        checkExpiry(event) {
            if (event.jsonData.isSingleDay) {
                return new Date(event.crb5c_date) > new Date();
            } else {
                return new Date(event.jsonData.multipleEvents[event.jsonData.multipleEvents.length - 1].date) > new Date();
            }
        },
        convertTo12HourFormat(time) {
            if (!time) return '';
            const [hours, minutes] = time.split(':');
            const period = +hours >= 12 ? 'PM' : 'AM';
            const adjustedHours = +hours % 12 || 12; 
            return `${adjustedHours}:${minutes} ${period}`;
        },
        truncateText(text, length) {
            if (text.length > length) {
                return text.substring(0, length) + "...";
            } else {
                return text;
            }
        },
        getEventStatus(event) {
            const currentDate = new Date();
            const eventStartDate = new Date(event.crb5c_date);
            const eventEndDate = this.lastDayOfEvent(event);

            if (eventStartDate > currentDate) {
                return { text: 'Upcoming', class: 'badge-success' };
            } else if (eventEndDate < currentDate) {
                return { text: 'Past', class: 'badge-danger' };
            } else {
                return { text: 'Ongoing', class: 'badge-primary' }; 
            }
        }
    }
}
</script>


<style lang="scss" scoped>
button{
    background-color: #8967b3;
    border: none !important;
}
</style>