<template lang="pug">
b-modal(v-model="showModal", title="Please review your submission before submitting", size="lg", centered scrollable)
    .container
        div(v-if="event.isSingleDay")
            div(v-if="submission.singleDay.whoGoing.length > 0")
                small Details of People Attending
                ul(class="list-unstyled" style="padding-left: 0;")
                    li(v-for="person in submission.singleDay.whoGoing" v-if="person === 'Other Persons' ? submission.singleDay.otherPersons.length > 0 : true" :key="person") 
                        p.font-weight-bold {{ person === 'Client' ? `${currentClientName()} -` : person === 'Caregiver' ? `${currentCaregiverId()} -` : "" }}  {{ person }}
                        div(v-if="person === 'Client' && (submission.singleDay.client.lunch || submission.singleDay.client.beverage)").mb-2
                            small Client's food and beverage
                            p.m-0.font-weight-bold {{ submission.singleDay.client.lunch }}
                            p.m-0.font-weight-bold {{ submission.singleDay.client.beverage }}
                        hr(v-if="person === 'Client' && submission.singleDay.client.lunch && submission.singleDay.client.beverage")
                        div(v-if="person === 'Caregiver' && (submission.singleDay.caregiver.lunch || submission.singleDay.caregiver.beverage)").mb-2
                            small Caregiver's food and beverage
                            p.m-0.font-weight-bold {{ submission.singleDay.caregiver.lunch }}
                            p.m-0.font-weight-bold {{ submission.singleDay.caregiver.beverage }}
                        hr(v-if="person === 'Caregiver' && submission.singleDay.caregiver.lunch && submission.singleDay.caregiver.beverage")
                        div(v-if="person === 'Other Persons' && submission.singleDay.otherPersons.length > 0")
                            small Other Persons
                            ul(class="list-unstyled" style="padding-left: 0;")
                                li(v-for="person in submission.singleDay.otherPersons" :key="Math.random()")
                                    p.m-0.font-weight-bold {{ person?.name }}
                                    div(v-if="person.lunch || person.beverage").mb-2
                                        small {{person?.name}}'s food and beverage
                                        p.m-0.font-weight-bold {{ person.lunch }}
                                        p.m-0.font-weight-bold {{ person.beverage }}
                                    hr(v-if="person.lunch && person.beverage")
                p.m-0(v-if="submission?.remarks") Remarks (If Any): {{ submission?.remarks }} 
                b-card-text.p.m-1.text-success Total $ {{ (totalSingleDayCost * event?.gst).toFixed(2) }} (GST included)
                        
        
        div(v-else)
            div(v-if="submission.multipleDays.whoGoing.length > 0")
                small Details of People Attending
                ul(class="list-unstyled" style="padding-left: 0;")
                    li(v-for="person in submission.multipleDays.whoGoing" :key="person"
                    v-if="person === 'Other Persons' ? submission.multipleDays.otherPersons.length > 0 : true"
                    ) 
                        p {{ person === 'Client' ? `${currentClientName()} -` : person === 'Caregiver' ? `${currentCaregiverId()} -` : "" }}  {{ person }}
                        div(v-if="person === 'Client' && submission.multipleDays.client.attendingDays.length > 0").mb-2
                            small Attending Days
                            ul(class="list-unstyled" style="padding-left: 0;")
                                li(v-for="day in submission.multipleDays.client.attendingDays" :key="day") 
                                    p.m-0.font-weight-bold {{ day }}
                                div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="submission.multipleDays.client.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))").my-2
                                        small.p.mb-1() Selected Food Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                        b-card-text.m-0.font-weight-bold {{ submission.multipleDays.client.lunchByDay.find(lunch => lunch.dayID === day.id)?.lunchOption }}
                                        b-card-text.m-0.font-weight-bold {{ submission.multipleDays.client.beverageByDay.find(beverage => beverage.dayID === day.id)?.beverageOption }}
                                        small.p.mb-2.text-primary(v-if="!submission.multipleDays.client.lunchByDay.find(lunch => lunch.dayID === day.id)  && !submission.multipleDays.client.beverageByDay.find(beverage => beverage.dayID === day.id) ") No Food Options Selected for this day
                        hr(v-if="person === 'Client' && submission.multipleDays.client.attendingDays.length > 0")
                        div(v-if="person === 'Caregiver' && submission.multipleDays.caregiver.attendingDays.length > 0").mb-2
                            small Attending Days
                            ul(class="list-unstyled" style="padding-left: 0;")
                                li(v-for="day in submission.multipleDays.caregiver.attendingDays" :key="day") 
                                    p.m-0.font-weight-bold {{ day }}
                                div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="submission.multipleDays.caregiver.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))").my-2
                                        small.p.mb-1() Selected Food Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                        b-card-text.m-0.font-weight-bold {{ submission.multipleDays.caregiver.lunchByDay.find(lunch => lunch.dayID === day.id)?.lunchOption }}
                                        b-card-text.m-0.font-weight-bold {{ submission.multipleDays.caregiver.beverageByDay.find(beverage => beverage.dayID === day.id)?.beverageOption }}
                                        small.p.mb-2.text-primary(v-if="!submission.multipleDays.caregiver.lunchByDay.find(lunch => lunch.dayID === day.id)  && !submission.multipleDays.caregiver.beverageByDay.find(beverage => beverage.dayID === day.id) ") No Food Options Selected for this day
                        hr(v-if="person === 'Caregiver' && submission.multipleDays.caregiver.attendingDays.length > 0")
                        div(v-if="person === 'Other Persons' && submission.multipleDays.otherPersons.length > 0")
                            ul(class="list-unstyled" style="padding-left: 0;")
                                li(v-for="person in submission.multipleDays.otherPersons" :key="person.id")
                                    p.m-0.font-weight-bold {{ person.name }}
                                    div(v-if="person.attendingDays.length > 0").mb-2
                                        small Attending Days
                                        ul(class="list-unstyled" style="padding-left: 0;")
                                            li(v-for="day in person.attendingDays" :key="day") 
                                                p.m-0.font-weight-bold {{ day }}
                                            div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="person.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))").my-2
                                                small.p.mb-1() Selected Food Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                                b-card-text.m-0.font-weight-bold {{ person.lunchByDay.find(lunch => lunch.dayID === day.id)?.lunchOption }}
                                                b-card-text.m-0.font-weight-bold {{ person.beverageByDay.find(beverage => beverage.dayID === day.id)?.beverageOption }}
                                                small.p.mb-2.text-primary(v-if="!person.lunchByDay.find(lunch => lunch.dayID === day.id)  && !person.beverageByDay.find(beverage => beverage.dayID === day.id) ") No Food Options Selected for this day
                                    hr(v-if="person.attendingDays.length > 0")
                    p Remarks: {{ submission?.remarks }} 
                    b-card-text.p.m-0.text-success Total $ {{ (totalMultipleDaysCost * event?.gst).toFixed(2) }} (GST included)
    template(v-slot:modal-footer)
        b-button(type="button" variant="secondary" @click="showModal = false") Make Changes
        b-button(type="button" variant="success" @click="submit") Submit

</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            submission: {
                type: Object,
                required: true
            },
            totalMultipleDaysCost: {
                type: Number,
                required: true
            },
            totalSingleDayCost: {
                type: Number,
                required: true
            }

        },

        computed:{
            showModal: {
                get(){
                    return this.show;
                },
                set(value){
                    this.$emit('update:show', value);
                }
            }
        },
        watch: {
            show: {
                handler: function (val) {
                    this.showModal = val;
                },
                immediate: true
            },
            event: {
                handler: function (val) {
                    console.log(val);
                },
                immediate: true
            },
            submission: {
                handler: function (val) {
                    console.log(val);
                },
                immediate: true
            }
        },
        methods:{
            submit(){
                this.showModal = false;
                this.$emit('submit');
            },
            currentClientName() {
            return this.$store.state.cli_name;
        },
        currentCaregiverId() {
            return this.$store.state.currentUser[0]?.crb5c_name
        }
        }
    }
</script>

<style lang="scss" scoped>

</style>