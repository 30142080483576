<template lang="pug">
div(class="mainBody")
    div(class="title my-3 mx-5")
        p(class="font-weight-bold h3") Clients
    //- Client List 
    //- client-list(v-for="user in users"
    //- :key="clients.crb5c_no"
    //- :name="user.crb5c_no"
    //- :imgsrc="user.imgsrc")
    //- p {{this.store.state.crb5c_fow_customerid}}
</template>

<script>
// @ is an alias to /src
import ClientList from '@/components/client/ClientList.vue'
//export var clidata = selectedCli;
export default {
    
    components: {
        ClientList
    },
    async mounted(){
        

        //let arr = [
        // $.get("/amplify/backend/function/dsgmobilelambda/src/routes/fow_client").then(({data})=>{ 
        //     console.log(data)
        //     //this.clList = data.care;
        //     //return data;
        // }).catch(()=>{
        //     alert("Unable to connect to the system. Please try your submission another time.");
        // })
        //]
        //Promise.all(arr).then(()=>{
        //this.loading =false;
    //})
    },

    inject:['users'] //client list
}
</script>

<style lang="scss" scoped>
</style>