import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import dayjs from "dayjs";
// import person1 from "@/assets/person1.jpg";
// import person2 from "@/assets/person2.jpg";
import events from './modules/events/events';
import axios from 'axios';

var customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default new Vuex.Store({
  state: {
    pinnedAnnouncementList: [],
    groupPhoto:"",
    loadingImage: false,
    currentUser: {
      loggedIn: false,
      data: {}
    },
    upcomingSession: {
      name: '',
      date: '',
      time: ''
    },
    twilio: {},
    caregiver:[],
    cli_id:"",
    cli_name:"",
    cli_surveystatus:"",
    cli_pic:null,
    sessionid:"",
    session_name:"",
    session_date:"",
    checkin:"",
    checkout:"",
    activity:[],
    activityid:"",
    activity_name:"",
    remark:"",
    users:[],
    pastsessions:[
      // {
      //   sessionid:'s1',
      //   sessionname:'Wednesday PM (Centre-Group)',
      //   imgsrc:person1,
      //   customername:'Client 2',
      //   time:dayjs("2022/12/18").format('YYYY-MM-DD'),
      //   checkin:'2:00pm',
      //   checkout:'4:00pm',
      //   activity:[
      //     {
      //       id: 1, 
      //       action:"Baking", 
      //       remark:"Client 2 did a very good job in making a delicious cupcake for the DSG staff",
      //       imgsrc:"https://images.unsplash.com/photo-1532499016263-f2c3e89de9cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
      //     },
      //     {id: 2, action:"Music & Arts", remark:"Client 2 did follow through every lyrics when singing a song and he drew a very beautiful flowers"},
      //     {id: 3, action:"Badminton", remark:"Client 2 was very active during the badminton session"},
      //   ]
      // },
      // {
      //   sessionid:'s2',
      //   sessionname:'Tuesday PM (Centre-Group)',
      //   imgsrc:person2,
      //   customername:'Client 1',
      //   time:dayjs("2022/12/13").format('YYYY-MM-DD'),
      //   checkin:'1:00pm',
      //   checkout:'3:00pm',
      //   activity:[
      //     {
      //       id: 1, 
      //       action:"Baking", 
      //       remark:"Client 2 did a very good job in making a delicious cupcake for the DSG staff",
      //       imgsrc:[
      //         "https://images.unsplash.com/photo-1532499016263-f2c3e89de9cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
      //         "https://images.unsplash.com/photo-1517686469429-8bdb88b9f907?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
      //       ]
      //     },
      //     {id: 2, action:"Music & Arts", remark:"Client 2 did follow through every lyrics when singing a song and he drew a very beautiful flowers"},
      //     {id: 3, action:"Badminton", remark:"Client 2 was very active during the badminton session"},
      //     {id: 4, action:"Dancing", remark:"Client 2 followed every steps that was done by the DSG staff during the dancing session"},
      //   ]
      // },
    ],
    stateFetchingSession: {  
      isFetchingSession: false,
      selectedMonth: null,
    },
    announcements:[
      {id:1, title:"Happy Holidays", desc:"Selamat Hari Raya Aidilfitri from DSG", date:dayjs("2022/05/01").format('YYYY-MM-DD')},
      {id:2, title:"Outdoor Activities", desc:"Garden By the Bay, feel free to join if you are interested", link:"https://forms.gle/RM8M6ReUjMNhqwtL9", time:'3:00pm' , date:dayjs("2022/12/18").format('YYYY-MM-DD')}
    ],
    reminders:[
      {id:1, title:"Late Payment", date:"September", desc:"Payment is due for the upcoming sessions, please pay to avoid cancellations"},
      // {id:2, title:"Session Cancellation", date:dayjs("2022/09/13").format('YYYY-MM-DD'), desc:"Sorry to inform you that your session have been cancelled"},
    ],
    invoice_lists:[
      {
          "id": "FOWTEST22-09-0001",
          "guid": "134a4b6d-1f3d-ed11-bba3-000d3a08543d",
          "link": "/main.aspx?appid=45923c4d-8cc1-ec11-983e-000d3ac885f2&pagetype=entityrecord&etn=crb5c_fowinvoice&id=134a4b6d-1f3d-ed11-bba3-000d3a08543d",
          "description": "Centre-based 3-HR FOW group session [$80] (Sep 2022)",
          "date": "2022-09-26T00:00:00Z",
          "month": "Sep '22",
          "amount": 80,
          "qty": 2,
          "subsidy": null,
          "subtotal": 160,
          "gst": 8,
          "gst_amt": 11,
          "fee_type": "Programme Fees",
          "total": 172.8,
          "paid": 0,
          "creditnote": 10,
          "due": 172.8,
          "sessions": [
              "2022-09-26 (PM)",
              "2022-09-19 (AM)"
          ]
      },
      {
          "id": "FOWTEST22-09-0002",
          "guid": "f5b131fc-5a3e-ed11-bba3-000d3a0855b0",
          "link": "/main.aspx?appid=45923c4d-8cc1-ec11-983e-000d3ac885f2&pagetype=entityrecord&etn=crb5c_fowinvoice&id=f5b131fc-5a3e-ed11-bba3-000d3a0855b0",
          "description": " 6 Month Neeurofit Subscription",
          "date": "2022-09-25T16:00:00Z",
          "month": "Sep '22",
          "amount": 240,
          "qty": 1,
          "subsidy": null,
          "subtotal": 40,
          "gst": 8,
          "gst_amt": 3,
          "fee_type": "NeeuroFIT and add-ons",
          "total": 259.2,
          "paid": 0,
          "creditnote": 0,
          "due": 259.2,
          "sessions": []
      },
    ],
    invoices:{
      "id": "FOWTEST22-09-0001",
      "guid": "134a4b6d-1f3d-ed11-bba3-000d3a08543d",
      "link": "/main.aspx?appid=45923c4d-8cc1-ec11-983e-000d3ac885f2&pagetype=entityrecord&etn=crb5c_fowinvoice&id=134a4b6d-1f3d-ed11-bba3-000d3a08543d",
      "description": "Centre-based 3-HR FOW group session [$80] (Sep 2022)",
      "date": "2022-09-26T00:00:00Z",
      "month": "Sep '22",
      "amount": 80,
      "qty": 2,
      "subsidy": null,
      "subtotal": 160,
      "gst": 7,
      "gst_amt": 11,
      "fee_type": "Programme Fees",
      "total": 171,
      "paid": 50,
      "creditnote": 10,
      "due": 111,
      "client_name": "TEST CLIENT (Invoicing)",
      "client_address_line1": "212 Geylang Rd,#11-02",
      "client_address_line2": "Singapore 123456",
      "sessions": [
          "26 Sep 2022 Tue (PM)",
          "26 Sep 2022 Tue (AM)",
          "26 Sep 2022 Tue (PM)",
          "26 Sep 2022 Tue (AM)",
          "26 Sep 2022 Tue (PM)",
          "26 Sep 2022 Tue (AM)",
          "26 Sep 2022 Tue (PM)"
      ],
      "list_all": [
          {
              "date": "2022-10-12T00:00:00Z",
              "amt": 10,
              "type": "cn"
          },
          {
              "date": "2022-10-10T00:00:00Z",
              "amt": 50,
              "type": "payment"
          }
      ],
      "list_cn": [
          {
              "date": "2022-10-12T00:00:00Z",
              "amt": 10,
              "type": "cn"
          }
      ],
      "list_payments": [
          {
              "date": "2022-10-10T00:00:00Z",
              "amt": 50,
              "type": "payment"
          }
      ],
    }
  },
  mutations: {
    setPinnedAnnouncementList(state, payload){
      state.pinnedAnnouncementList = payload;
    },
    setGroupPhoto(state, payload){
      state.groupPhoto = payload;
    },
    setLoadingImage(state, payload){
      state.loadingImage = payload;
    },
    setCliName(state, payload) {
      state.cli_name = payload;
    },
    setCliSurveyStatus(state, surveystatus) {
      state.cli_surveystatus = surveystatus;
    },
    setCurrentUserData(state, payload) {
      state.currentUser = payload;
    },
    setUsers(state, clients){
      state.users = clients;
    },
    setSelectedUserId(state, id) {
      state.cli_id = id;
    },
    setPastSessions(state, payload){
      state.pastsessions = payload;
    },
    setIsFetchingSession(state, { isFetchingSession }){
      state.stateFetchingSession.isFetchingSession = isFetchingSession;
    },
    setSelectedDate(state, { selectedMonth }){
      state.stateFetchingSession.selectedMonth = selectedMonth;
    },
    setCare(state, care){
      state.caregiver = care
    },
    setTwilio(state, twilio){
      state.twilio = twilio
    },
    setUpcomingSession(state, upcomingSession) {
      state.upcomingSession = upcomingSession
    }
  },
  actions: {
    async fetchPinnedAnnouncementList({ commit }){
    try{
      while (!this.state.currentUser) {
        await new Promise(resolve => setTimeout(resolve, 200));
    }     
    if (this.state.currentUser[0] && this.state.currentUser[0]?.nextSession){
      // console.log("in")
      if(this.state.pinnedAnnouncementList.length === 0){
      const sessionIds = this.$root.currentUser[0]?.nextSession?.map(ses => ses.session?._crb5c_sessionscheduler_value) || [];
      const {data} = await axios.get(`/api/announcements/v2`,{
          params: { groupID: sessionIds }
      });
      let {pinned} = data;
        // console.log("pinned", pinned);
      commit('setPinnedAnnouncementList', pinned);
      }

    }else{
      console.log("out")
    }
  }catch(err){
    console.log(err);
  }
    },
    async fetchImage({ commit, state }) {
      if (!state.groupPhoto) {
          commit('setLoadingImage', true);
          try {
              let response = await axios.get('/api/sharepoint');
              if (response.data) {
                  const existingImage = response.data.data.value[0];
                  const downloadUrl = existingImage['@microsoft.graph.downloadUrl'];
                  if (downloadUrl) {
                      let image = await axios.get(downloadUrl, { responseType: 'blob' });
                      const imageUrl = URL.createObjectURL(image.data);
                      // console.log('imageUrl', image);
                      commit('setGroupPhoto', imageUrl);
                  }
              }
          } catch (err) {
              console.error("Error fetching image", err);
          }
          finally {
              commit('setLoadingImage', false);
          }
      }
  },

    async fetchSelectedMonth({ state, commit }, { selectedMonth }){
      try {
        commit('setIsFetchingSession', { isFetchingSession: true });
        // let selected = this.$store.state.cli_id;
        const selectedClientId = state.cli_id;

        // if(!selectedClientId){
        //   throw new Error("No client id");
        // }

        if(!selectedClientId || selectedClientId === ""){
          return;
        }
  
        const formatSelectedMonth = dayjs(selectedMonth);
        const modifyFormatSelectedMonth = formatSelectedMonth.endOf("month").toISOString();
  

        let { data } = await axios.post("/api/past_sessions", {
          selected: selectedClientId,
          month: modifyFormatSelectedMonth,
        });
  
        commit("setPastSessions", data);
        commit('setIsFetchingSession', { isFetchingSession: false });
        
      } catch (error) {
        // console.error(error);

        throw new Error(error);
        
      }
    }
  },
  modules: {
    events,
  }
})
