<!-- USED IN  ReminderPage.vue -->
<template lang="pug">
div(class="mainBody d-flex px-3 py-2 my-4 border border-dark shadow")
    div(class="iconBody mx-3 my-auto")
        b-icon(class="h1 rounded-circle border border-info p-2" icon='calendar2-fill')
    div(class="contextBody flex-column")
        p.h5.font-weight-bold {{title}}
        p.h6.font-weight-bold {{date}}
        p.h6.mt-1 {{desc}}
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        date: {
            type: String,
        },
        desc: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>
.mainBody {
    border-radius: 20px;
}
</style>