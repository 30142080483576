export function validateEvent(event, foodSelected) {
    if (event.event.isSingleDay) {
        if (foodSelected.singleDay.whoGoing.length === 0) {
            return { isValid: false, message: 'Please select who will be attending' };
        }
        if(!foodSelected.singleDay?.whoGoing.includes('Client')) {
            return { isValid: false, message: 'Please select Client' };
        }

        if (event.event.singleDay.food.lunch.length > 1) {
            if (foodSelected.singleDay.whoGoing.includes('Client') && !foodSelected.singleDay.client.lunch) {
                return { isValid: false, message: 'Please select lunch for Client' };
            }

            if (foodSelected.singleDay.whoGoing.includes('Caregiver') && !foodSelected.singleDay.caregiver?.lunch) {
                return { isValid: false, message: 'Please select lunch for Caregiver' };
            }

            for (let person of foodSelected.singleDay.otherPersons) {
                if (!person.lunch) {
                    return { isValid: false, message: `Please select lunch for ${person.name}` };
                }
            }
        }

        if (event.event.singleDay.food.beverages.length > 1) {
            if (foodSelected.singleDay.whoGoing.includes('Client') && !foodSelected.singleDay.client.beverage) {
                return { isValid: false, message: 'Please select beverage for Client' };
            }

            if (foodSelected.singleDay.whoGoing.includes('Caregiver') && !foodSelected.singleDay.caregiver?.beverage) {
                return { isValid: false, message: 'Please select beverage for Caregiver' };
            }

            for (let person of foodSelected.singleDay.otherPersons) {
                if (!person.beverage) {
                    return { isValid: false, message: `Please select beverage for ${person.name}` };
                }
            }
        }

    } else { 
        
        if (!foodSelected.multipleDays.whoGoing.includes('Client')) {
            return { isValid: false, message: 'Please select Client' };
        }
        if (foodSelected.multipleDays.whoGoing.length === 0) {
            return { isValid: false, message: 'Please select who will be attending' };
        }

        
        if (foodSelected.multipleDays.whoGoing.includes('Client')) {
            if (foodSelected.multipleDays.client.attendingDays.length === 0) {
                return { isValid: false, message: 'Please select days for Client' };
            }

            for (let day of foodSelected.multipleDays.client.attendingDays) {
                const dayIndex = event.event.multipleEvents.findIndex(eventDay => 
                    new Date(eventDay.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) === day
                );

                
                if (event.event.multipleEvents[dayIndex].food.lunch.length > 0) {
                    const lunchOption = getLunchOptionForDay(event.event.multipleEvents[dayIndex].id, foodSelected.multipleDays.client.lunchByDay);
                    if (!lunchOption) {
                        return { isValid: false, message: `Please select lunch for Client on ${day}` };
                    }
                }

                if (event.event.multipleEvents[dayIndex].food.beverages.length > 0) {
                    const beverageOption = getBeverageOptionForDay(event.event.multipleEvents[dayIndex].id, foodSelected.multipleDays.client.beverageByDay);
                    if (!beverageOption) {
                        return { isValid: false, message: `Please select beverage for Client on ${day}` };
                    }
                }
            }
        }

        if (foodSelected.multipleDays.whoGoing.includes('Caregiver')) {
            if (foodSelected.multipleDays.caregiver.attendingDays.length === 0) {
                return { isValid: false, message: 'Please select days for Caregiver' };
            }

            // for (let day of foodSelected.multipleDays.caregiver.attendingDays) {
            //     const dayIndex = event.event.multipleEvents.findIndex(eventDay => 
            //         new Date(eventDay.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) === day
            //     );

            //     if (event.event.multipleEvents[dayIndex].food.lunch.length > 0) {
            //         const lunchOption = getLunchOptionForDay(event.event.multipleEvents[dayIndex].id, foodSelected.multipleDays.caregiver.lunchByDay);
            //         if (!lunchOption) {
            //             return { isValid: false, message: `Please select lunch for Caregiver on ${day}` };
            //         }
            //     }

            //     if (event.event.multipleEvents[dayIndex].food.beverages.length > 0) {
            //         const beverageOption = getBeverageOptionForDay(event.event.multipleEvents[dayIndex].id, foodSelected.multipleDays.caregiver.beverageByDay);
            //         if (!beverageOption) {
            //             return { isValid: false, message: `Please select beverage for Caregiver on ${day}` };
            //         }
            //     }
            // }
        }

        
        for (let person of foodSelected.multipleDays.otherPersons) {
            if (person.attendingDays.length === 0) {
                return { isValid: false, message: `Please select days for ${person.name}` };
            }

            // for (let day of person.attendingDays) {
            //     const dayIndex = event.event.multipleEvents.findIndex(eventDay => 
            //         new Date(eventDay.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) === day
            //     );

            //     if (event.event.multipleEvents[dayIndex].food.lunch.length > 0) {
            //         const lunchOption = getLunchOptionForDay(event.event.multipleEvents[dayIndex].id, person.lunchByDay);
            //         if (!lunchOption) {
            //             return { isValid: false, message: `Please select lunch for ${person.name} on ${day}` };
            //         }
            //     }

            //     if (event.event.multipleEvents[dayIndex].food.beverages.length > 0) {
            //         const beverageOption = getBeverageOptionForDay(event.event.multipleEvents[dayIndex].id, person.beverageByDay);
            //         if (!beverageOption) {
            //             return { isValid: false, message: `Please select beverage for ${person.name} on ${day}` };
            //         }
            //     }
            // }
        }

        
      
    }

    
    return { isValid: true };
}


function getLunchOptionForDay(dayID, lunchByDay) {
    return lunchByDay.find(lunch => lunch.dayID === dayID);
}

function getBeverageOptionForDay(dayID, beverageByDay) {
    return beverageByDay.find(beverage => beverage.dayID === dayID);
}

