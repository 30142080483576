
const phoneVibrate = () => {
    if(!window){
        return;
      }

      if(!window.navigator){
        return;
      }

      if(!window.navigator.vibrate){
        return;
      }

      const vibratePattern = [200, 100, 200];
      navigator.vibrate(vibratePattern);
}

export { phoneVibrate }