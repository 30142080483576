<template lang="pug">
div(class="d-flex m-10 clickable announcement shadow-sm" @click="showAnnouncementDetails")
    //- div(class="iconBody mr-3 my-auto")
    //-     b-icon(class="h1 rounded-circle border border-info p-2" icon='calendar2-fill')
    div(class="contextBody d-flex flex-column mx-2 text-wrap")
        div.d-flex.align-items-start.justify-content-between.flex-column
            p.m-0.font-weight-bold(class="title") {{ $root.formatTitle(title, isEvent, groupName) }}
            p.my-auto(:style="`color: ${dateColor}; font-size: 0.8rem;`") {{ formatDate(dateTime) }}
        span.m-0(class="desc p-2 text-wrap" style="white-space: pre-wrap; text-align: justify;" v-html="desc" v-if="desc.includes('<')")
        span.m-0(class="desc" style="white-space: pre-wrap; text-align: justify;" v-if="!desc.includes('<')") {{ desc }}

    b-modal#announcement-details(v-model="showModal" ok-only centered)
        template(#modal-title)
            div(class="m-0 font-weight-bold") {{ `${$root.formatTitle(title,isEvent,groupName)}`}}
            small(style="font-size: 0.9rem;") {{  `Announced on ${formatDate(dateTime)}` }}
        p.font-weight-bold(v-if="groupName && !isEvent") Announcement For: {{ groupName }} Group
        div(v-if="isEvent")
            p.font-weight-bold(v-if="groupName") Announcement For: {{ groupName }} Group
            p.font-weight-bold Event Date: {{ $root.dayjs(eventDate, eventDateFormat).format('DD/MM/YYYY') }}
            p.font-weight-bold Event Time: {{ $root.dayjs(`${eventDate} ${eventTime}`,eventTimeFormat).format('h:mm A') }}
        span(v-if="desc.includes('<')" v-html="desc" class="p-2 text-wrap")
        span(v-if="!desc.includes('<')") {{desc}}

</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        title: {
            type: String,
        },
        desc: {
            type: String,
        },
        dateTime: {
            type: String,
        },
        isEvent: {
            type: Boolean
        },
        eventDate: {
            type: String
        },
        eventTime: {
            type: String
        },
        groupName: {
            type: String
        }
    },
    data() {
        return {
            showModal: false,
            dateColor: "#0984e3",
        }
    },
    methods: {
        showAnnouncementDetails() {
            this.showModal = true;
        },
        formatDate(rawDate){
            return dayjs(rawDate).format('DD MMM YY, hh:mm A');
        },
    },
    computed: {
        eventDateFormat() {
            if (!this.eventDate) {
                return '';
            }
            return this.eventDate.includes('-') ? 'YYYY-MM-DD' : 'DD/MM/YYYY'
        },
        eventTimeFormat() {
            if (!this.eventDate) {
                return '';
            }
            return this.eventDate.includes('-') ? 'YYYY-MM-DD HH:mm' : 'DD/MM/YYYY HH:mm'
        } 
    }
    }
</script>

<style lang="sass" scoped>
.announcement
    
    padding: 10px
    
    margin-bottom: 10px
    cursor: pointer
    transition: all 0.3s
    &:hover
        background-color: #0984e3
        color: white
        .title
            color: white
        .desc
            color: white

</style>
