<template lang="pug">
.mainBody
  b-container
    back-button
    b-modal(id="success-modal" centered title="Success" header-bg-variant="success" header-text-variant="white" no-close-on-backdrop no-close-on-esc hide-header-close hide-footer)
      h5 Survey has been submitted sucessfully
      .d-flex 
        b-button.mx-auto(@click="redirectHome()") OK
    b-modal(id="error-modal" centered title="Error" header-bg-variant="danger" header-text-variant="white" no-close-on-backdrop no-close-on-esc hide-header-close hide-footer)
      h5 There seems to be an error
      .d-flex
        b-button.mx-auto(@click="redirectHome()") OK
    //- --------------------------
    div.d-flex.text-center.justify-content-center(v-if="loading")
      b-spinner.my-5(label="Loading...")
    b-card.my-2(header-bg-variant="dsg-primary" v-if="!loading" header-text-variant="white" no-body)
      template(#header)
        div.d-flex.justify-content-between
          h5(v-if="!surveystatus")  {{chineseOptions ?  "过 去 的 调 查" : "Past Survey" }}
          h5(v-if="surveystatus") {{chineseOptions ?  "负 担 问 卷" : "Zarit Screen Measure" }}
          //- b-button(@click="consoleLogTest") test
          b-form-checkbox(switch v-model="chineseOptions") {{ chineseOptions ? "中文" : "English"}}
      div(v-if="!surveystatus")
        //- h5 {{  chineseOptions? "您 目 前 不 需 要 填 写 他 们 的 调 查 问 卷" : "You are currently not required to fill in their survey"}}
        b-tabs(v-if="past_survey_items.length> 0"  pills card justified)
          b-tab(v-for="tab in sortedTabs" :key="tab.crb5c_zaritscreenmeasureoffamilycaregiverburdenid" :title="formatDateOnly(tab.createdon)")
            div
              label {{ chineseOptions ? "你 是 否 觉 得 因 为 花 了 时 间 在 你 亲 人 身 上 而 造 成 自 己 没 有 足 够 的 时 间 ？*" : "Do you feel that because of the time you spend with your relative that you don't have enough time for yourself?*" }}
              br
              b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion1).variant")
                span {{ getZaritAnswer(tab.crb5c_zaritquestion1).answer }}
            hr
            div
              label {{ chineseOptions ? "你 是 否 因 为 要 照 料 你 亲 人 ，同 时 又 要 应 付 家 庭 或 工 作 上 的 其 他 责 任 而 觉 得 有 压力 ？*" : "Do you feel stressed between caring for your relative and trying to meet other responsibilities for your family or work?*" }}
              br
              b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion2).variant")
                span {{ getZaritAnswer(tab.crb5c_zaritquestion2).answer }}
            hr
            div
              label {{ chineseOptions ? "当 你 和 你 亲 人 在 一 起 时 ，你 是 否 感 到 不 自 在 ？*" : "Do you feel strained when you are around your relative?*" }}
              br
              b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion3).variant")
                span {{ getZaritAnswer(tab.crb5c_zaritquestion3).answer }}
            hr
            div
              label {{ chineseOptions ? "你 是 否 觉 得 不 能 确 定 要 为 你 亲 人 做 什 么 ？*" : "Do you feel uncertain about what to do about your relative?*" }}
              br
              b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion4).variant")
                span {{ getZaritAnswer(tab.crb5c_zaritquestion4).answer }}
            hr
            div
              label {{ chineseOptions ? "总 的 来 说，你 在 照 顾 亲 戚 方 面 感 到 负 担 有 多 大 ？*" : "Overall, how burdened do you feel in caring for your relative?" }}
              br
              b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion5).variant")
                span {{ getZaritAnswer(tab.crb5c_zaritquestion5).answer }}
            hr
            div
              label {{ chineseOptions ? "总 的 来 说，我 对 FOW 很 满 意，因 为 它 符 合 我 的 期 望 ？*" : "Overall, I am satisfied with FOW, as it met my expectations?" }}
              br
              b-badge(:variant="getZaritFinalAnswer(tab.crb5c_zaritquestion6).variant")
                span {{ getZaritFinalAnswer(tab.crb5c_zaritquestion6).answer }}
        div.p-3(v-if="past_survey_items.length == 0")
          h5  {{ chineseOptions ? "未找到已提交的调查" : "No Survey Submitted Found" }}
      div.p-3(v-if="surveystatus")
        .ruleSection(v-if="currentSection === 'ruleSection'")
          p(v-if="!chineseOptions") The questions below reflect how you, as caregiver, sometimes feel when you are taking care of your loved one with dementia. After each statement, rate the number that best describes how often you feel that way three months ago and now. There is no right or wrong answer. We ask you to answer these questions periodically to measure your level of stress and how it changes.
          p(v-if="chineseOptions") 以 下 问 题 反 映 当 你 照 顾 你 亲 人 时 的 感 受 。请 在 每 道 问 题 的 右 边 填写号码 出 最 能 形 容 你 的 感 受 之 选 项, 三 个 月 前 和 现 在。没 有 任 何 答 案 是 对 或 错 的。我 们 要 求 您 回 答 这 些 问 题 来 恒 量 你 照 顾 你 的 失 智 症 的 家 属 的 压 力 程 度。        
          hr
          p.font-weight-bold {{ chineseOptions ? "我 们 想 了 解 你 的 感 受" : "Rate the response that is to best describe how you feel" }}
          .buttonGroup.d-flex
            b-button.mx-auto.bg-dsg-primary.text-white(@click="changeSection('questionSection')") {{ chineseOptions ?  "进行" : "Proceed"}} 
        .questionSection(v-if="currentSection === 'questionSection'")
          b-form(@submit.prevent="submitSurvey()")
            b-form-group
              label {{ chineseOptions ? "你 是 否 觉 得 因 为 花 了 时 间 在 你 亲 人 身 上 而 造 成 自 己 没 有 足 够 的 时 间 ？*" : "Do you feel that because of the time you spend with your relative that you don't have enough time for yourself?*" }}
              b-form-select(:options="zaritOptions" v-model="zarit_obj.crb5c_zaritquestion1" required)
            hr
            b-form-group
              label {{ chineseOptions ? "你 是 否 因 为 要 照 料 你 亲 人 ，同 时 又 要 应 付 家 庭 或 工 作 上 的 其 他 责 任 而 觉 得 有 压力 ？*" : "Do you feel stressed between caring for your relative and trying to meet other responsibilities for your family or work?*" }}
              b-form-select(:options="zaritOptions" v-model="zarit_obj.crb5c_zaritquestion2" required)
            hr
            b-form-group
              label {{ chineseOptions ? "当 你 和 你 亲 人 在 一 起 时 ，你 是 否 感 到 不 自 在 ？*" : "Do you feel strained when you are around your relative?*" }}
              b-form-select(:options="zaritOptions" v-model="zarit_obj.crb5c_zaritquestion3" required)
            hr
            b-form-group
              label {{ chineseOptions ? "你 是 否 觉 得 不 能 确 定 要 为 你 亲 人 做 什 么 ？*" : "Do you feel uncertain about what to do about your relative?*" }}
              b-form-select(:options="zaritOptions" v-model="zarit_obj.crb5c_zaritquestion4" required)
            hr
            b-form-group
              label {{ chineseOptions ? "总 的 来 说，你 在 照 顾 亲 戚 方 面 感 到 负 担 有 多 大 ？*" : "Overall, how burdened do you feel in caring for your relative?" }}
              b-form-select(:options="zaritOptions" v-model="zarit_obj.crb5c_zaritquestion5" required)
            hr
            b-form-group
              label {{ chineseOptions ? "总 的 来 说，我 对 FOW 很 满 意，因 为 它 符 合 我 的 期 望 ？*" : "Overall, I am satisfied with FOW, as it met my expectations?" }}
              b-form-select(:options="zaritFinalOptions" v-model="zarit_obj.crb5c_zaritquestion6" required)
            hr
            .buttonGroup.d-flex.justify-content-between
              b-button.mx-auto(variant="warning" @click="changeSection('ruleSection')") {{chineseOptions ? "返回" : "Back"}}
              b-button.mx-auto(variant="primary" type="submit") {{ chineseOptions ? "提交" : "Submit" }} 
</template>

<script>
// @ is an alias to /src
import dayjs from "dayjs";
import axios from "axios";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js"; // dependent on utc plugin
import BackButton from "@/components/BackButton.vue";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Singapore");
import { mapState } from "vuex";

export default {
  data() {
    return {
      // for sort
      loading:false,
      past_survey_items:[],
      currentMonth: dayjs().month(),
      selectedMonth: null,
      pastsessionsArray: [],
      fetching: false,
      isSortAscending: false,
      chineseOptions:false,
      currentSection:"ruleSection",
      surveystatus:null,
      zaritOptions:[
        {text:"Please Choose 请选择", value: null},
        {text:"Never 从不", value: 0},
        {text:"Rarely 很少", value: 1},
        {text:"Sometimes 有时", value: 2},
        {text:"Quite Frequently 经常", value: 3},
        {text:"Nearly Always 总是", value: 4},
      ],
      zaritFinalOptions:[
        {text:"Strongly Agree 非常同意", value: 6},
        {text:"Agree 同意", value: 5},
        {text:"Somewhat Agree 有些同意", value: 4},
        {text:"Somewhat Disagree 有些不同意", value: 3},
        {text:"Disagree 不同意", value: 2},
        {text:"Strongly Disagree 非常不同意", value: 1},
        {text:"Please Choose 请选择", value: null},
      ],
      zarit_obj:{
        crb5c_zaritquestion1:null,
        crb5c_zaritquestion2:null,
        crb5c_zaritquestion3:null,
        crb5c_zaritquestion4:null,
        crb5c_zaritquestion5:null,
        crb5c_zaritquestion6:null,
      },
      caregiverId:null,
      clientId:null,
    };
  },
  components: {
    BackButton
  },
  methods: {
    // consoleLogTest(){
    //   console.log(this.caregiverObj);
    // },
    getZaritAnswer(value) {
      let answer = "";
      let variant = "";
      if (value === 0) {
          answer = "Never 从不";
          variant = "success";
      } else if (value === 1) {
          answer = "Rarely 很少";
          variant = "success";
      } else if (value === 2) {
          answer = "Sometimes 有时";
          variant = "warning";
      } else if (value === 3) {
          answer = "Quite Frequently 经常";
          variant = "danger";
      } else if (value === 4) {
          answer = "Nearly Always 总是";
          variant = "danger";
      } else {
          answer = "Not Recorded";
          variant = "info";
      }
      return { answer, variant };
    },
    getZaritFinalAnswer(value) {
      let answer = "";
      let variant = "";
      if (value === 1) {
          answer = "Strongly Disagree 非常不同意";
          variant = "danger";
      } else if (value === 2) {
          answer = "Disagree 不同意";
          variant = "danger";
      } else if (value === 3) {
          answer = "Somewhat Disagree 有些不同意";
          variant = "warning";
      } else if (value === 4) {
          answer = "Somewhat Agree 有些同意";
          variant = "success";
      } else if (value === 5) {
          answer = "Agree 同意";
          variant = "success";
      } else if (value === 6) {
          answer = "Strongly Agree 非常同意";
          variant = "success";
      } else {
          answer = "Not Recorded";
          variant = "info";
      }
      return { answer, variant };
    },
    formatDateOnly(date){
        return dayjs(date).format('DD/MM/YYYY')
    },
    changeSection(section) {
      this.currentSection = section;
    },
    async submitSurvey(){
      this.loading = true;
      try{
        this.zarit_obj.caregiverId = this.currentCaregiverId
        this.zarit_obj.clientId = this.currentClientId
        // console.log("Test",this.caregiverObj);
        const { data } = await axios.post("/api/survey", this.zarit_obj);
        console.log(data);
        this.$bvModal.show('success-modal')
      }catch{
        this.$bvModal.show('error-modal')
      }
      this.loading = false;
    },
    redirectHome(){
      this.$router.push(`/home`)
    }
  },
  async mounted() {
    this.loading = true;
    const userData = this.$store.state.currentUser;
    if(userData[0]){
      let phone_obj = {
        phone: userData[0].crb5c_contactnumbermobile
      }
      const response = await axios.post("/api/caregiver_info", phone_obj);
      // console.log(response.data.caregiveData[0].crb5c_crb5c_zaritscreenmeasureoffamilycaregiver);
      this.past_survey_items = response.data.caregiveData[0].crb5c_crb5c_zaritscreenmeasureoffamilycaregiver;
      this.surveystatus = response.data.caregiveData[0].crb5c_surveystatus;
      // console.log(this.surveystatus);
    }
    this.loading = false;
  },
  computed: {
    ...mapState({
      caregiver: state => state.caregiver,
      currentuser: state => state.currentUser
    }),
    sortedTabs() {
        return this.past_survey_items.slice().sort((a, b) => {
            return new Date(b.createdon) - new Date(a.createdon);
        });
    },
    currentClientName(){
      return this.$store.state.cli_name;
    },
    currentClientId(){
      return this.$store.state.cli_id
    },
    caregiverObj() {
      return this.$store.state.caregiver;
    },
    currentCaregiverId(){
      return this.$store.state.currentUser[0].crb5c_fow_caregiverid
    }
  },
  watch: {
    async currentuser(value){
      // console.log("current", value[0].crb5c_contactnumbermobile);
      if(value[0]){
        let phone_obj = {
          phone: value[0].crb5c_contactnumbermobile
        }
        const response = await axios.post("/api/caregiver_info", phone_obj);
        // console.log(response.data.caregiveData[0].crb5c_crb5c_zaritscreenmeasureoffamilycaregiver);
        this.past_survey_items = response.data.caregiveData[0].crb5c_crb5c_zaritscreenmeasureoffamilycaregiver;
        this.surveystatus = response.data.caregiveData[0].crb5c_surveystatus;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$base-color-purple: #50276b;
$base-color-yellow: #ffcd06;
.blockButton {
  background-color: transparent;
  color: $base-color-purple;
  border: 2px solid $base-color-purple;
}
</style>