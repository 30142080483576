<template lang="pug">
.mainBody.position-sticky.px-3
  b-row(style="height: 15vh;")
    b-col.leftHeader.d-flex.justify-content-center.align-items-center(cols="2")
      b-icon.sidebarIcon.h1(icon="list", v-b-toggle.sidebar-no-header style="position: relative;")

      div.text-white.font-weight-bold.d-flex.justify-content-center.align-items-center(
        v-if="countAllNotification !== 0"
        style="width: 1.2rem; height: 1.2rem; font-size: 0.7rem; position: absolute; background-color: red; top: 20%; right: 1%; border-radius: 1rem;"
      ) 
        | {{ countAllNotification }}
      b-sidebar#sidebar-no-header(
        shadow 
        backdrop 
        width="300px")
        ul.py-3.px-3
          router-link(to="/home")
            li.mx-3.mb-3
              div(v-if="$route.path === '/home'")
                p.h5.mb-2.currentPageHighlight
                  b-icon.menuIcon.mx-2(icon="house-fill")
                  | Home
              div(v-else)
                p.h5.mb-2
                  b-icon.menuIcon.mx-2(icon="house-fill")
                  | Home
          //- router-link(to="/client")
          //-     li(class="mx-3 mb-3")
          //-         p.h5.mb-2
          //-             b-icon(class="menuIcon mx-2" icon='people-fill')
          //-             |View Client
          router-link(to="/session") <!-- home -->
            li.mx-3.mb-3
              div(v-if="$route.path === '/session'")
                p.h5.mb-2.currentPageHighlight
                  b-icon.menuIcon.mx-2(icon="journal-bookmark-fill")
                  | Past Sessions
              div(v-else)
                p.h5.mb-2
                  b-icon.menuIcon.mx-2(icon="journal-bookmark-fill")
                  | Past Sessions
          router-link(to="/announcement")
            li.mx-3.mb-3
              div(v-if="$route.path === '/announcement'")
                p.h5.mb-2.currentPageHighlight
                  b-icon.menuIcon.mx-2(icon="calendar-check-fill")
                  | Announcements
              div(v-else)
                p.h5.mb-2
                  b-icon.menuIcon.mx-2(icon="calendar-check-fill")
                  | Announcements
            
          //- router-link(to="/reminder")
          //-   li.mx-3.mb-3
          //-     p.h5.mb-2
          //-       b-icon.menuIcon.mx-2(icon="bell-fill")
          //-       | Reminders (Sample)

          router-link(to="/billing")
            li.mx-3.mb-3
              p.h5.mb-2
                b-icon.menuIcon.mx-2(icon="cash-stack")
                | Billing (Sample)

          //- router-link(to="/events")
          //-   li.mx-3.mb-3(style="position: relative;")
          //-     div(v-if="$route.path === '/events'")
          //-       p.h5.mb-2.currentPageHighlight
          //-         b-icon.menuIcon.mx-2(icon="calendar3-event-fill")
          //-         span Events
          //-     div(v-else)
          //-       p.h5.mb-2
          //-         b-icon.menuIcon.mx-2(icon="calendar3-event-fill")
          //-         span Events
          //-     div.text-white.font-weight-bold.d-flex.justify-content-center.align-items-center(
          //-       v-if="countEventsNotification !== 0"
          //-       style="width: 1.2rem; height: 1.2rem; font-size: 0.7rem; position: absolute; background-color: red; top: 20%; right: 1%; border-radius: 1rem;"
          //-     ) 
          //-       | {{ countEventsNotification }}

          router-link(to="/messaging")
            li.mx-3.mb-3(style="position: relative;")
              div(v-if="$route.path === '/messaging'")
                p.h5.mb-2.currentPageHighlight
                  b-icon.menuIcon.mx-2(icon="chat-dots-fill")
                  | Chat with Admin
              div(v-else)
                p.h5.mb-2
                  b-icon.menuIcon.mx-2(icon="chat-dots-fill")
                  | Chat with Admin
              div.text-white.font-weight-bold.d-flex.justify-content-center.align-items-center(
                v-if="countAdminChatNotification !== 0"
                style="width: 1.2rem; height: 1.2rem; font-size: 0.7rem; position: absolute; background-color: red; top: 20%; right: 1%; border-radius: 1rem;"
              ) 
                | {{ countAdminChatNotification }}
          router-link(to="/contact")
            li.mx-3.mb-3
              div(v-if="$route.path === '/contact'")
                p.h5.mb-2.currentPageHighlight
                  b-icon.menuIcon.mx-2(icon="telephone-fill")
                  | Contact Us 
              div(v-else)
                p.h5.mb-2
                  b-icon.menuIcon.mx-2(icon="telephone-fill")
                  | Contact Us
          router-link(to="/survey")
            li.mx-3
              p.h5
                b-icon.menuIcon.mx-2(icon="pencil-fill")
                | Survey
          li.logoutBtn.mx-3.mb-3(@click="logout")
            p.h5.mb-2
              b-icon.menuIcon.mx-2(icon="box-arrow-in-right")
              | Logout
    b-col.d-flex.justify-content-between(cols="10")
      b-overlay.my-auto(:show="!$store.state.caregiver.name" variant="transparent")
        .d-flex.flex-column
          p.my-auto Good {{ timeOfDayGreet }},
          h4 {{ caregiverName }}
      .d-flex
        b-avatar.my-auto(v-b-modal.modal-1, size="lg" :src="`data:image/png;base64,${currentUserPhoto}`")
        b-modal#modal-1(centered, title="Choose Client")
          div(v-if="!$root.changingClient")
            ul
              li.clientBtn.mr-5.my-3.p-2.border.border-dark.shadow(
                v-for="(value, key) in users"
                @click="changeState(value.id, value.name, value.photo)"
                style="cursor: pointer;"
              )
                .d-flex.align-items-center
                  img.profileImageBody.rounded-circle(
                    :src="`data:image/png;base64,${value.photo}`"
                    @error="fallbackImg"
                  )
                  p.h4.ml-2 {{ value.name }} {{ isActive(value.id) ? ' (Active)' : '' }}
          div(v-else)
            b-overlay.my-5(show)
              template(#overlay)
                p.text-center Switching client... Please wait.
          template(#modal-footer="{ cancel }")
            b-button(size="sm", @click="cancel") Close

</template>

<script>
import fallbackClientImg from '@/assets/user.png';
import { EVENTS_NAMESPACE } from "../store/modules/events/types-events";
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  data() {
    return {
    };
  },
  methods: {
    isActive(id) {
      return this.$store.state.cli_id === id ? true : false
    },
    fallbackImg(e) {
      e.target.src = fallbackClientImg;
    },
    async changeState(id) {
      if (this.isActive(id)) {
        return;
      }

      this.$root.changingClient = true;

      const phone = localStorage.getItem('phone');
      const country = localStorage.getItem('country');

      const { data } = await this.$root.axios.post(`/change_client/`, {
        phone,
        country,
        client_id: id
      });

      // console.log("data after changing client: ", data);

      if (data.success) {
        await this.$root.setEverything(data, phone, country);

        const userData = data.userData;
        userData.chatToken = data.chatToken;
        this.$store.commit('setCliName', userData?.crb5c_client?.crb5c_no)
        this.$store.commit("setCurrentUserData", [userData]);
        this.$store.commit("setUpcomingSession", data.nextSession);

        this.$store.commit("setCare", {
          name: userData.crb5c_name,
          email: userData.crb5c_email,
        });

        //--------
        this.$store.commit("setSelectedUserId", id);

        this.$store.commit("setTwilio", data.chatToken);
        await this.$root.initChat(data.chatToken);

        if(!this.$router.path === "/home"){
          this.$router.push("/home");
        }

        this.$root.changingClient = false;
        this.$bvModal.hide('modal-1')
        return true;
      }
      

      /*
      //console.log(message)
      let selected = id;
      this.$store.commit("setSelectedUserId", id);
      //console.log(this.$store.state.cli_id)
      this.$store.state.cli_name = name;
      //console.log(this.$store.state.cli_name)
      this.$store.state.cli_pic = photo;
      console.log(this.$store.state.caregiver);
      //console.log(this.$store.state.crb5c_photo)
      axios.post("api/upcoming_sessions", { selected });
      axios.post("api/past_sessions", {
        selected,
      });*/  
      
    },
    getCurrentCaregiverId(){
      if(!this?.currentUser[0]?.crb5c_fow_caregiverid){
        return null;
      }
      return this.currentUser[0].crb5c_fow_caregiverid;
    },
    checkStartLoginDateIs30Days(){
      const startLoginDateInISO = localStorage.getItem("startLoginDateInISO");
      if(!startLoginDateInISO || startLoginDateInISO === ""){
        return null;
      }

      const todaysDate = dayjs();
      const differentDate = dayjs(todaysDate).diff(startLoginDateInISO, "day");
      const MAX_LOGIN_DAYS = 30;

      if(differentDate === MAX_LOGIN_DAYS){
        return true;
      }

      return false;

    },
    logout(){
      localStorage.clear();
      this.$router.push({ path: "/login" });

    }
  },
  computed: {
    ...mapState({
      currentUser: "currentUser",
      listData: state => (state[EVENTS_NAMESPACE].listData),
    }),
    countEventsNotification(){
      const presentListData = this.listData
      .filter(item => dayjs().isBefore(dayjs(item.date)));

      if(presentListData.length === 0){
        return 0;
      }

      const listEventNotJoinYet = presentListData
      .filter(item => item.caregivers.length === 0)

      let listEventNotJoinYet2 = 0;

      if(this.getCurrentCaregiverId()){
        listEventNotJoinYet2 = presentListData
        .filter(item => item.caregivers.length !== 0)
        .filter(itemFilter => itemFilter.id === this.getCurrentCaregiverId())
      }

      const totalEventNotJoinYet = listEventNotJoinYet.length + listEventNotJoinYet2.length;

      return totalEventNotJoinYet;

    },
    countAdminChatNotification(){
      const mapAdminChatMessages = this.$root.chatMessages.map(item => ({
        id: item.sid,
        name: item.author,
        message: item.body,
        admin: item.attributes?.admin,
      }));

      let count = 0;

      for (let i = mapAdminChatMessages.length - 1; i >= 0; i--) {
        if (mapAdminChatMessages[i].admin === undefined) {
          break; //
        }
        count++;
      }

      return count;

    },
    countAllNotification(){
      const eventNotification = this.countEventsNotification;
      const adminChatNotification = this.countAdminChatNotification;

      return eventNotification + adminChatNotification;
      // return eventNotification;

    },
    currentClient(){
      return this.$store.state.cli_name;
    },
    timeOfDayGreet() {
      let hour = this.$root.dayjs().hour();
      return hour < 12 ? 'Morning' : hour < 13 ? 'Afternoon' : 'Evening';
    },
    users() {
      return this.$store.state.users;
    },
    currentUserPhoto() {
      return this.$store.state?.users?.find(u => u.id === this.$store.state?.cli_id)?.photo || null
    },
    caregiverName() {
      return this.$store.state?.caregiver?.name
    },
  },
  mounted(){
    if(this.checkStartLoginDateIs30Days()){
      alert("Login expired, please login again.");
      this.logout();
    }
    
  }
};
</script>

<style lang="scss" scoped>
$base-color-purple: #50276b;
.mainBody {
  background-color: $base-color-purple;
  color: white;
  z-index: 99;
  top: 0;
}

ul {
  list-style: none;
}

li.logoutBtn {
  position: absolute;
  bottom: 2rem;
}

a {
  color: black;
  text-decoration: none;
}
.clientBtn {
  border-radius: 20px;
}
.profileImageBody {
  width: 80px;
  height: 80px;
  margin: 5px;
}

.currentPageHighlight {
  color: $base-color-purple;
}

</style>
