<template lang="pug">
div(class="mainBody")
    div(class="title d-flex flex-column m-5")
        div.mx-auto.my-3
            b-icon(class="homeButton font-weight-bold rounded-circle p-5" font-scale="10" variant="light" icon='search')
        div.d-flex.m-auto
            div(class="d-flex")
                p(class="font-weight-bold h1 ") Oops!
        p(class="font-weight-bold h4 mx-auto text-center mt-3") We can't seem to find the page you're looking for
        b-button(class="homeButton mx-5 mt-4 p-3 font-weight-bold shadow" to="/home" pill) Back to Home
</template>

<script>
// @ is an alias to /src
import ClientList from '@/components/client/ClientList.vue'

export default {
    components: {
        ClientList
    },
    inject: ['pastsessions']
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;

.homeButton{
    background-color: #50276B;
}

</style>