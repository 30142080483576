<!-- USED IN SessionHistory.vue -->
<template lang="pug">
div(class="mainBody d-flex px-3 py-2 my-4 ")
    div.d-flex.flex-column.w-75
        p.font-weight-bold.mt-2 {{ changeSessionName(sessionname) }}
        div(class="d-flex")
            p Date:
            p.ml-2 {{time.date }} ({{ time.type }})
    div.d-flex.flex-column.my-auto.w-25
        b-button(class="detailButton" :to="'/session/' + sessionid") Details
</template>

<script>
export default {
    props: {
        sessionid:{
            type:String,
        },
        sessionname: {
            type: String,
        },
        customername: {
            type: String,
        },
        time: {
            type: Object,
        },
    },
    methods: {
        changeSessionName(sessionName){
            if(!sessionName || sessionName === ""){
                return;
            }
            
            return sessionName.split(" ").slice(0, 2).join(" ");

        },
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;

.detailButton{
    background-color: #8967b3;
    border: none !important;
}

.mainBody {
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>