<template lang="pug">
div(class="mainBody")

    b-modal(id="FOWTEST22-09-0001" title="Invoice" size="lg" scrollable centered)
        b-container
            b-row
                b-col.col-12.text-center
                    b-img(src="/pdf-one-first.jpeg" height="700px" alt="invoice")
            b-row
                b-col.col-12.text-center
                    b-img(src="/pdf-one-second.jpeg" height="700px" alt="invoice")
        template(#modal-footer)
            b-button(@click="hideInvoice") Close
    
    b-modal(id="FOWTEST22-09-0002" title="Invoice" size="lg" scrollable centered)
        b-container
            b-row
                b-col.col-12.text-center
                    b-img(src="/pdf-two-second.jpeg" height="700px" alt="invoice")
            b-row
                b-col.col-12.text-center
                    b-img(src="/pdf-two-first.jpeg" height="700px" alt="invoice")
        template(#modal-footer)
            b-button(@click="hideInvoice") Close

    div(class="title my-3 mx-5")
        p(class="font-weight-bold h4") Billing and Payment Portal (Sample)
    b-card(no-body)
        b-tabs(card justified pills v-model="tab_number")
            b-tab(title="Summary")
                b-card-text
                    h4.text-center Summary Invoices (Sample)
                    b-table(
                        :items="invoice_lists" 
                        :fields="fields1" bordered striped
                    )
                        template(#cell(id)="data")
                            span.text-primary(
                                style="cursor: pointer;"
                                @click="openInvoice(data.item.id)"
                            ) {{ data.item.id }}
                    .d-flex.justify-content-end.bg-light.p-3.rounded.text-dark
                        div
                            p.h5.font-weight-bold Total Amount:
                            p.h5.font-weight-bold (GST Inclusive)
                        p.h5.ml-3.font-weight-bold ${{invoice_list_due.toFixed(2)}}
                    .buttonGroup.mt-2.d-flex.justify-content-around
                        b-button(class="p-2" @click="tab_number=3") Pay Outstanding ({{invoice_lists.length}})
            b-tab(title="Outstanding Invoices")
                b-card-text
                        b-table(id="tableBody" :items="invoice_lists" :fields="fields2" :per-page="perPage" :current-page="currentPage" stacked bordered striped)
                        b-pagination(class="mx-auto" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="tableBody" align="center" size="lg")
            b-tab(title="Payment History")
                b-card-text
                    b-table(:items="payment_list" stacked bordered striped empty-text="No Payments Records Found" show-empty)
            b-tab(title="Payment Instruction")
                b-card-text
                    b-container
                        .text-center.mt-3
                            p.lead Pay Outstanding Invoices
                            div(class="qrcode")
                                vue-qrcode(:value="paynowString" :options="qroptions")
                                img(src="@/assets/new_paynow.png" alt="paynow")
                        div(class="mx-auto")
                            p(class="text-danger") Note that this interactive QR code will expire if not scanned by 2359hrs today
                            p(class="mt-3") To make payment via PayNow, scan the PayNow QR code using a compatible Mobile Banking App
                            hr
                            p(class="lead font-weight-bold") Details:
                            p Payment for Invoice(s):
                                p.font-weight-bold {{invoice_list_display}}
                            p Amount: $ {{invoice_list_due}}
                            p Reference:
</template>

<script>
// @ is an alias to /src
import dayjs from "dayjs"
import VueQrcode from '@chenfengyuan/vue-qrcode';
import PaynowQR from 'paynowqr'

export default {
    data() {
        return {
            modalInvoiceId: null,
            // listPdfUrl: ["../assets/sample-invoice-pdf/FOWTEST22.pdf", "../assets/sample-invoice-pdf/FOWTEST2202.pdf"],
            // pdfBase64String: null,
            // for tabs bar
            tab_number: 0,
            //summary table field
            fields1: [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'month',
                    label: 'Month',
                },
                {
                    key: 'due',
                    label: 'Amount Due ($)'
                },
            ],
            //outstanding invoices table field
            fields2: [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'due',
                    label:'Amount Due ($)'
                },
            ],
            //for pagination
            perPage: 1,
            currentPage: 1,
        }
    },
    computed:{
        paynowString() {
            let qrcode = new PaynowQR({
                uen: '202111519KDSG',           //Required: UEN of company
                amount: this.invoice_list_due,               //Specify amount of money to pay.
                refNumber: 'this.payment_reference',   //Reference number for Paynow Transaction. Useful if you need to track payments for recouncilation.
                company: 'Dementia Singapore Ltd. - Acc 1',   //Company name to embed in the QR code. Optional.
                expiry: dayjs().format("YYYYMMDD")
            });
            return qrcode.output();
        },
        qroptions() {
            return {
                errorCorrectionLevel: "H",
                color: {
                    dark: "#7C1978"
                }
            }
        },
        invoice_list_due(){
            return this.invoice_lists.map(a => a.due).reduce((a, b) => a + b, 0);
        },
        invoice_list_display() {
            return this.invoice_lists.map(a => a.id).join(", ");
        },
        //for pagination
        rows() {
            return this.invoice_lists.length
        }
    },
    methods: {
        async openInvoice(invoiceId){
            try {
                this.modalInvoiceId = invoiceId;

                this.$nextTick(() => {
                    this.$bvModal.show(this.modalInvoiceId);
                });
                
            } catch (error) {
                console.error(error);
            }
        },
        hideInvoice(){
            this.$bvModal.hide(this.modalInvoiceId);
            this.pdfBase64String = null;

            this.modalInvoiceId = null;
        },
        pdfToBase64(pdfFile) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
                };
                reader.onerror = (error) => {
                reject(error);
                };
                // Read the PDF file as a blob
                reader.readAsDataURL(pdfFile);
            });
        }
    },
    components: {
        VueQrcode,
    },
    inject: ['invoice_lists'],
}
</script>

<style lang="scss" scoped>
.qrcode {
    position: relative;
}

.qrcode img {
    height: 20%;
    left: 50%;
    position: absolute;
    overflow: hidden;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>