<template lang="pug">
div(class="mainBody")
    b-container
        b-button(block class="blockButton d-flex align-content-center mt-3 font-weight-bold" @click="$router.go(-1)")
                b-icon-arrow-left-circle-fill
                | Back
        div(class="mainBody d-flex flex-column p-2")
            h3(class="text-center mt-3") Session Details
            //- session-profile(:session="session" :name="name" :imgsrc="imgsrc")
            div.mt-4(class="secondSection d-flex flex-column p-1")
                p.mt-4(class="h5 mb-4 font-weight-bold") Session Info:
                session-date(:date="date")
                div(class="mt-5 d-flex flex-column p-1")
                    p(class="h5 mb-4 font-weight-bold") Arrival Info:
                    b-card.mt-2
                        //- h3(v-if='headers.sessionRemark' style='padding:20px;margin-bottom:10px') Session Remark: {{headers.sessionRemark}}
                        //- b-row(align-h='center')
                            //- b-col(cols='3' v-for='pic in pics')
                            //-     img.image-list.shadow-lg.rounded.my-3(v-bind:src="'data:image/png;base64,' + pic.image")
                        div
                            b-row.px-3
                                b-col.no_border_full
                                b-col.border_full_color
                                    | Start
                                b-col.border_full_color
                                    | End
                            b-row.px-3
                                b-col.border_full_color Mood
                                b-col.border_full
                                    | {{mood(headers.mood_start)}}
                                b-col.border_full
                                    | {{mood(headers.mood_end)}}
                            b-row.px-3
                                b-col.border_full_color Behaviour
                                b-col.border_full
                                    | {{behaviour(headers.behaviour_start)}}
                                b-col.border_full
                                    | {{behaviour(headers.behaviour_end)}}
                                
                                
                            p.mt-5(class="h5 font-weight-bold") Activities Done:
                            //- b-table-simple
                            //- b-thead
                            //- b-tr
                            //-     b-th.colcolor
                            //-     b-th.colcolor(style='width:20%') Start
                            //-     b-th.colcolor(style='width:20%') End
                            //- b-tbody
                            //- b-tr.row-center
                            //-     b-th.colcolor Mood
                            //-     b-td {{mood(headers.mood_start)}}
                            //-     b-td {{mood(headers.mood_end)}}
                            //- b-tr.row-center
                            //-     b-th.colcolor Behaviour
                            //-     b-td(style="border-bottom: 1px solid #dee2e6;") {{behaviour(headers.behaviour_start)}}
                            //-     b-td(style="border-bottom: 1px solid #dee2e6;") {{behaviour(headers.behaviour_end)}}
                            
                            b-card.background-card(v-if='physicalActivity.length')
                                b-row.mt-4.px-3.separator.mb-4.mx-1
                                    h6 Physical
                                div(style='display: flex;flex-wrap: wrap;')
                                    .activity_list(v-for='(activity,index) in physicalActivity', :key='`activity+${index}`')
                                        div {{index + 1}}. {{activity.name}}
                                .mt-5| Observation:
                                div.mt-2.px-3.mb-5
                                    b-row
                                        b-col.border_full_color Attention Span 
                                        b-col.border_full {{attention(physicalActivity[0].attentionspan)}}
                                    b-row
                                        b-col.border_full_color Behaviour 
                                        b-col.border_full {{behaviour(physicalActivity[0].behaviour)}}
                                    b-row
                                        b-col.border_full_color Level Of Alertness 
                                        b-col.border_full {{alertness(physicalActivity[0].levelofalertness)}}
                                    b-row
                                        b-col.border_full_color Mood 
                                        b-col.border_full {{mood(physicalActivity[0].mood)}}
                                    b-row
                                        b-col.border_full_color Participation
                                        b-col.border_full {{participation(physicalActivity[0].participation)}}
                                    b-row
                                        b-col.border_full_color Thoughts
                                        b-col.border_full {{thought(physicalActivity[0].thoughts)}}
                                    b-row.mt-3.border_full_color Activity remarks
                                    b-row.mt-3 {{physicalActivity[0].remarks ? physicalActivity[0].remarks : 'None'}}
                                hr
                                    //- .table-container.mt-3
                                    //-     b-table-simple
                                    //-     b-tbody.row-center
                                    //-         b-tr
                                    //-         b-th.colcolor(style='width:32%') Attention Span
                                    //-         b-td {{attention(physicalActivity[0].attentionspan)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Behaviour
                                    //-         b-td {{behaviour(physicalActivity[0].behaviour)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Level Of Alertness
                                    //-         b-td {{alertness(physicalActivity[0].levelofalertness)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Mood
                                    //-         b-td {{mood(physicalActivity[0].mood)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Participation
                                    //-         b-td {{participation(physicalActivity[0].participation)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Thoughts
                                    //-         b-td {{thought(physicalActivity[0].thoughts)}}
                                    //- b-table-simple
                                    //-     b-tbody
                                    //-         b-tr.row-center
                                    //-         b-th.colcolor(style='width:32%') Activity remarks
                                    //-         b-td {{physicalActivity[0].remarks}}
                                            
    
                            b-card.background-card(v-if='psychoSocioActivity.length')
                                b-row.mt-4.px-3.separator.mb-4.mx-1
                                    h6 Psycho-Socio
                                div(style='display: flex;flex-wrap: wrap;')
                                    .activity_list(v-for='(activity,index) in psychoSocioActivity', :key='`activity+${index}`')
                                        div {{index + 1}}. {{activity.name}}
                                .mt-5| Observation:
                                div.mt-2.px-3.mb-4
                                    b-row
                                        b-col.border_full_color Attention Span 
                                        b-col.border_full {{attention(psychoSocioActivity[0].attentionspan)}}
                                    b-row
                                        b-col.border_full_color Behaviour 
                                        b-col.border_full {{behaviour(psychoSocioActivity[0].behaviour)}}
                                    b-row
                                        b-col.border_full_color Level Of Alertness 
                                        b-col.border_full {{alertness(psychoSocioActivity[0].levelofalertness)}}
                                    b-row
                                        b-col.border_full_color Mood 
                                        b-col.border_full {{mood(psychoSocioActivity[0].mood)}}
                                    b-row
                                        b-col.border_full_color Participation
                                        b-col.border_full {{participation(psychoSocioActivity[0].participation)}}
                                    b-row
                                        b-col.border_full_color Thoughts
                                        b-col.border_full {{thought(psychoSocioActivity[0].thoughts)}}
                                    b-row.mt-3.border_full_color Activity remarks
                                    b-row.mt-3 {{psychoSocioActivity[0].remarks ? psychoSocioActivity[0].remarks : 'None'}}
                            hr
                                    //- .table-container.mt-3
                                    //-     b-table-simple
                                    //-     b-tbody.row-center
                                    //-         b-tr
                                    //-         b-th.colcolor(style='width:32%') Attention Span
                                    //-         b-td {{attention(psychoSocioActivity[0].attentionspan)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Behaviour
                                    //-         b-td {{behaviour(psychoSocioActivity[0].behaviour)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Level Of Alertness
                                    //-         b-td {{alertness(psychoSocioActivity[0].levelofalertness)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Mood
                                    //-         b-td {{mood(psychoSocioActivity[0].mood)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Participation
                                    //-         b-td {{participation(psychoSocioActivity[0].participation)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Thoughts
                                    //-         b-td {{thought(psychoSocioActivity[0].thoughts)}}
                                    //-     b-table-simple
                                    //-     b-tbody
                                    //-         b-tr.row-center
                                    //-         b-th.colcolor(style='width:32%') Activity remarks
                                    //-         b-td {{psychoSocioActivity[0].remarks}}  

                        
                            b-card.background-card(v-if='cognitiveActivity.length')
                                b-row.mt-4.px-3.separator.mb-4.mx-1
                                    h6 Cognitive
                                div(style='display: flex;flex-wrap: wrap;')
                                    .activity_list(v-for='(activity,index) in cognitiveActivity', :key='`activity+${index}`')
                                        div {{index + 1}}. {{activity.name}}

                                .mt-5| Observation:
                                div.mt-2.px-3.mb-4
                                    b-row
                                        b-col.border_full_color Attention Span 
                                        b-col.border_full {{attention(cognitiveActivity[0].attentionspan)}}
                                    b-row
                                        b-col.border_full_color Behaviour 
                                        b-col.border_full {{behaviour(cognitiveActivity[0].behaviour)}}
                                    b-row
                                        b-col.border_full_color Level Of Alertness 
                                        b-col.border_full {{alertness(cognitiveActivity[0].levelofalertness)}}
                                    b-row
                                        b-col.border_full_color Mood 
                                        b-col.border_full {{mood(cognitiveActivity[0].mood)}}
                                    b-row
                                        b-col.border_full_color Participation
                                        b-col.border_full {{participation(cognitiveActivity[0].participation)}}
                                    b-row
                                        b-col.border_full_color Thoughts
                                        b-col.border_full {{thought(cognitiveActivity[0].thoughts)}}
                                    b-row.mt-3.border_full_color Activity remarks
                                    b-row.mt-3 {{cognitiveActivity[0].remarks ? cognitiveActivity[0].remarks : 'None'}}
                            hr
                                    //- .table-container.mt-3
                                    //-     b-table-simple
                                    //-     b-tbody.row-center
                                    //-         b-tr
                                    //-         b-th.colcolor(style='width:32%') Attention Span
                                    //-         b-td {{attention(cognitiveActivity[0].attentionspan)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Behaviour
                                    //-         b-td {{behaviour(cognitiveActivity[0].behaviour)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Level Of Alertness
                                    //-         b-td {{alertness(cognitiveActivity[0].levelofalertness)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Mood
                                    //-         b-td {{mood(cognitiveActivity[0].mood)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Participation
                                    //-         b-td {{participation(cognitiveActivity[0].participation)}}
                                    //-         b-tr
                                    //-         b-th.colcolor Thoughts
                                    //-         b-td {{thought(cognitiveActivity[0].thoughts)}}
                                    //-     b-table-simple
                                    //-     b-tbody
                                    //-         b-tr.row-center
                                    //-         b-th.colcolor(style='width:32%') Activity remarks
                                    //-         b-td {{cognitiveActivity[0].remarks}} 

                                        
                            b-card.background-card(v-if='namasteActivity.length')
                                b-row.mt-4.px-3.separator.mb-4.mx-1
                                    h6 Namaste Care
                                div(style='display: flex;flex-wrap: wrap;')
                                    .activity_list(v-for='(activity,index) in namasteActivity', :key='`activity+${index}`')
                                        div {{index + 1}}. {{activity.name}}
                                
                                .mt-5| Observation:
                                div.mt-2.px-3.mb-4
                                    b-row
                                        b-col.no_border_full
                                        b-col.border_full_color
                                            | Start
                                        b-col.border_full_color
                                            | End
                                    b-row
                                        b-col.border_full_color Facial Expression
                                        b-col.border_full
                                            | {{nfacial(namasteActivity[0].facialBegin)}}
                                        b-col.border_full
                                            | {{nfacial(namasteActivity[0].facialEnd)}}
                                    b-row
                                        b-col.border_full_color Eye Contact
                                        b-col.border_full
                                            | {{neeye(namasteActivity[0].eyeContactBegin)}}
                                        b-col.border_full
                                            | {{neeye(namasteActivity[0].eyeContactEnd)}}
                                    b-row
                                        b-col.border_full_color Interest in Talking
                                        b-col.border_full
                                            | {{ninterest(namasteActivity[0].interestBegin)}}
                                        b-col.border_full
                                            | {{ninterest(namasteActivity[0].interestEnd)}}
                                    b-row
                                        b-col.border_full_color Body Posture
                                        b-col.border_full
                                            | {{nbody(namasteActivity[0].postureBegin)}}
                                        b-col.border_full
                                            | {{nbody(namasteActivity[0].postureEnd)}}
                                    b-row
                                        b-col.border_full_color Mood
                                        b-col.border_full
                                            | {{mood(namasteActivity[0].moodBegin)}}
                                        b-col.border_full
                                            | {{mood(namasteActivity[0].moodEnd)}}
                                    b-row
                                        b-col.border_full_color Behaviour
                                        b-col.border_full {{behaviour(namasteActivity[0].behaviourBegin)}}
                                        b-col.border_full
                                            | {{behaviour(namasteActivity[0].behaviourEnd)}}
                                    b-row.mt-3.border_full_color Activity remarks
                                    b-row.mt-3 {{namasteActivity[0].remarks ? namasteActivity[0].remarks : 'None'}}
                                        
                            
                                
                                //- .table-container.mt-3
                                //-     b-table-simple
                                //-     b-thead
                                //-         b-tr
                                //-         b-th.colcolor
                                //-         b-th.darkcolcolor(style='width:33%') Beginning of Session
                                //-         b-th.darkcolcolor(style='width:33%') End of Session
                                //-     b-tbody
                                //-         b-tr.row-center
                                //-         b-th.colcolor(style='width:30%') Facial Expression
                                //-         b-td {{nfacial(namasteActivity[0].facialBegin)}}
                                //-         b-td {{nfacial(namasteActivity[0].facialEnd)}}
                                //-         b-tr.row-center
                                //-         b-th.colcolor Eye Contact
                                //-         b-td {{neeye(namasteActivity[0].eyeContactBegin)}}
                                //-         b-td {{neeye(namasteActivity[0].eyeContactEnd)}}
                                //-         b-tr.row-center
                                //-         b-th.colcolor Interest in Communication
                                //-         b-td {{ninterest(namasteActivity[0].interestBegin)}}
                                //-         b-td {{ninterest(namasteActivity[0].interestEnd)}}
                                //-         b-tr.row-center
                                //-         b-th.colcolor Body Posture
                                //-         b-td {{nbody(namasteActivity[0].postureBegin)}}
                                //-         b-td {{nbody(namasteActivity[0].postureEnd)}}
                                //-         b-tr.row-center
                                //-         b-th.colcolor Mood
                                //-         b-td {{mood(namasteActivity[0].moodBegin)}}
                                //-         b-td {{mood(namasteActivity[0].moodEnd)}}
                                //-         b-tr.row-center
                                //-         b-th.colcolor Behaviour
                                //-         b-td {{behaviour(namasteActivity[0].behaviourBegin)}}
                                //-         b-td {{behaviour(namasteActivity[0].behaviourEnd)}}
                                //-     b-table-simple
                                //-     b-tbody
                                //-         b-tr.row-center
                                //-         b-th.colcolor(style='width:32%') Activity remarks
                                //-         b-td {{namasteActivity[0].remarks}}
                                //- b-card(v-for='(activity,index) in orderedReports' :key='`activity+${index}`')
                    //-     h3.headertitle Activity {{index + 1}} - {{activity.name}} 
                    //-         span(v-if='activity.type != 3') ({{acttype(activity.type)}})
                    //-     div(v-if='activity.type == 3 || activity.type == 4' style="overflow-y: auto;")
                    //-         b-table-simple
                    //-             b-thead
                    //-             b-tr
                    //-                 b-th.colcolor
                    //-                 b-th.darkcolcolor(style='width:33%') Beginning of Session
                    //-                 b-th.darkcolcolor(style='width:33%') End of Session
                    //-             b-tbody 
                    //-             b-tr.row-center
                    //-                 b-th.colcolor(style='width:30%') Facial Expression
                    //-                 b-td {{nfacial(activity.facialBegin)}}
                    //-                 b-td {{nfacial(activity.facialEnd)}}
                    //-             b-tr.row-center
                    //-                 b-th.colcolor Eye Contact
                    //-                 b-td {{neeye(activity.eyeContactBegin)}}
                    //-                 b-td {{neeye(activity.eyeContactEnd)}}
                    //-             b-tr.row-center
                    //-                 b-th.colcolor Interest in Communication
                    //-                 b-td {{ninterest(activity.interestBegin)}}
                    //-                 b-td {{ninterest(activity.interestEnd)}}
                    //-             b-tr.row-center
                    //-                 b-th.colcolor Body Posture
                    //-                 b-td {{nbody(activity.postureBegin)}}
                    //-                 b-td {{nbody(activity.postureEnd)}}
                    //-             b-tr.row-center
                    //-                 b-th.colcolor Mood
                    //-                 b-td {{mood(activity.moodBegin)}}
                    //-                 b-td {{mood(activity.moodEnd)}}
                    //-             b-tr.row-center
                    //-                 b-th.colcolor Behaviour
                    //-                 b-td {{behaviour(activity.behaviourBegin)}}
                    //-                 b-td {{behaviour(activity.behaviourEnd)}}
                    //-             b-tbody
                    //-             b-tr.row-center
                    //-                 b-th.colcolor Activity remarks
                    //-                 b-td(style="border-bottom: 1px solid #dee2e6;") {{activity.remarks ? activity.remarks : '-'}}
                    //-             b-tr.row-center(v-if='activity.type == 3')
                    //-                 b-th.colcolor Activities done
                    //-                 b-td {{activity.namasteactivities}}
                    //-     div(v-else)
                    //-     b-table-simple
                    //-         b-tbody.row-center
                    //-         b-tr
                    //-             b-th.colcolor(style='width:32%') Attention Span
                    //-             b-td {{attention(activity.attentionspan)}}
                    //-         b-tr
                    //-             b-th.colcolor Behaviour
                    //-             b-td {{behaviour(activity.behaviour)}}
                    //-         b-tr
                    //-             b-th.colcolor Level Of Alertness
                    //-             b-td {{alertness(activity.levelofalertness)}}
                    //-         b-tr
                    //-             b-th.colcolor Mood
                    //-             b-td {{mood(activity.mood)}}
                    //-         b-tr
                    //-             b-th.colcolor Participation
                    //-             b-td {{participation(activity.participation)}}
                    //-         b-tr
                    //-             b-th.colcolor Thoughts
                    //-             b-td {{thought(activity.thoughts)}}
                    //-         b-tr
                    //-             b-th.colcolor Activity remarks
                    //-             b-td(style="border-bottom: 1px solid #dee2e6;") {{activity.remarks ? activity.remarks : '-'}}

</template>

<script>
// @ is an alias to /src
import SessionProfile from '@/components/session/SessionProfile.vue'
import SessionDate from '@/components/session/SessionDate.vue'
import SessionActivity from '@/components/session/SessionActivity.vue'
import axios from 'axios';
import _ from 'lodash';

export default {
    data(){
        return{
            session: '',
            name:'',
            imgsrc:'',
            date:'',
            checkin:'',
            checkout:'',
            duration: '',
            activity:[],
            activityImg:[],
            headers: {},
            reports: [],
            pics: [],
            physicalActivity: [],
            psychoSocioActivity : [],
            cognitiveActivity: [],
            namasteActivity: [],

        }
    },
    components: {
        SessionProfile,
        SessionActivity,
        SessionDate
    },
    async created() {
        await this.$root.loginAndFetch();
        const sessionId = this.$route.params.sessionId;
        let selectedSession = this.pastsessions.find(pastsession => pastsession?.details?.session?.crb5c_fow_sessionid === sessionId);
        if (!selectedSession) {
            let selected = this.$store.state.cli_id;

            let { data } = await axios.post("/api/past_sessions", {
                selected,
                specific_id: sessionId
            });
            // console.log(data)
            this.$store.commit("setPastSessions", data);
        }
        const clientPhoto = this.$store.state?.currentUser?.[0]?.crb5c_client?.crb5c_photo ? `data:image/png;base64,${this.$store.state?.currentUser?.[0]?.crb5c_client?.crb5c_photo}` : '';
        selectedSession = this.pastsessions.find(pastsession => pastsession?.details?.session?.crb5c_fow_sessionid === sessionId);
        const session = selectedSession?.details;
        const sessionDetails = session?.session;

        // console.log("sessionDetails: ", sessionDetails);
        this.session = sessionDetails?.crb5c_fow_sessionid;
        this.name = sessionDetails?.crb5c_sessionname;
        this.imgsrc = clientPhoto;
        this.date = sessionDetails?.crb5c_date;
        this.checkin = session.checkin;
        this.checkout = session.checkout;
        this.duration = sessionDetails.crb5c_duration;
        this.headers = selectedSession.headers;
        this.reports = selectedSession.reports;
        this.pics = selectedSession.pics;
        // console.log('this.headers',this.headers)
        // console.log('this.reports',this.reports)

        this.physicalActivity = this.reports.filter((item) => {
                return item.type === 0 && (item.facialBegin || item.participation);
            });
        this.psychoSocioActivity = this.reports.filter((item) => {
                return item.type === 1 && (item.facialBegin || item.participation);
            });
            this.cognitiveActivity = this.reports.filter((item) => {
                return item.type === 2 && (item.facialBegin || item.participation);
            });
        this.namasteActivity = this.reports.filter((item) => {
                return item.type === 3 && (item.facialBegin || item.participation);
            });

        // console.log('this.physicalActivity', this.physicalActivity)
        // console.log('this.namasteActivity',this.namasteActivity)
        // console.log('this.cognitiveActivity',this.cognitiveActivity)
        // console.log('this.psychoSocioActivity',this.psychoSocioActivity)
        // const selectedActivity = selectedSession.activity;
        // const activityArray = []
        // for (let i = 0; i < selectedActivity.length;i++){
        //     activityArray.push(selectedActivity[i]);
        // }
        // this.activity=activityArray
        // console.log(activityArray[0].imgsrc)
        // const sessionId = this.$route.params.sessionId;
        // console.log(sessionId);
    },
    computed: {
        pastsessions() {
            return this.$store.state?.pastsessions || []
        },
        orderedReports(){
            return _.orderBy(this.reports, 'order') || []
        },
        
    },
    methods: {
        acttype(val) {
                        switch (val) {
                            case 0: return 'Physical';
                            case 1: return 'Psychosocio';
                            case 2: return 'Cognitive';
                            case 3: return 'Namaste Care';
                            case 4: return 'Individual';
                        }
                    },
                    attention(val) {
                        // console.log("attention: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Good (>30 min)';
                                case '1': return 'Fair (15-30 min)';
                                case '2': return 'Poor (<15 min)';
                            }
                        }).join(', ');
                    },
                    alertness(val) {
                        // console.log("alertness: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');
                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Alert';
                                case '1': return 'Lethargic';
                                case '2': return 'Fluctuating';
                            }
                        }).join(', ');
                    },
                    mood(val) {
                        // console.log("mood: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Elated';
                                case '1': return 'Happy';
                                case '2': return 'Neutral';
                                case '3': return 'Irritable';
                                case '4': return 'Angry';
                                case '5': return 'Anxious';
                                case '6': return 'Sad';
                                case '7': return 'Depressed';
                            }
                        }).join(', ');
                    },
                    behaviour(val) {
                        // console.log("behaviour: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Cooperative';
                                case '1': return 'Uncooperative';
                                case '2': return 'Calm';
                                case '3': return 'Restless';
                                case '4': return 'Agitated';
                                case '5': return 'Aggressive';
                            }
                        }).join(', ');
                    },
                    participation(val) {
                        // console.log("participation: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Yes';
                                case '1': return 'Sometimes';
                                case '2': return 'Never';

                            }
                        }).join(', ');
                    },
                    thought(val) {
                        // console.log("thought: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Relevant';
                                case '1': return 'Irrelevant';
                                case '2': return 'Inconsistent';
                                case '3': return 'Preoccupied';
                                case '4': return 'Delusional';
                            }
                        }).join(', ');
                    },
                    nfacial(val) {
                        // console.log("nfacial: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Passive';
                                case '1': return 'Gimmacing';
                                case '2': return 'Frowning';
                                case '3': return 'Frightened';
                                case '4': return 'Smilling';
                            }
                        }).join(', ');
                    },
                    neeye(val) {
                        // console.log("neeye: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Closed Eyes';
                                case '1': return 'Looking Away';
                                case '2': return 'Passive';
                                case '3': return 'Blank Look';
                                case '4': return 'Little Eye Contact';
                                case '5': return 'Good Eye Contact';
                            }
                        }).join(', ');
                    },
                    ninterest(val) {
                        // console.log("ninterest: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Avoiding';
                                case '1': return 'Listening';
                                case '2': return 'Non-verbal Response';
                                case '3': return 'Unclear verbal response';
                                case '4': return 'Clear verbal Response';
                            }
                        }).join(', ');
                    },
                    nbody(val) {
                        // console.log("nbody: ", val);
                        if(!val){
                            return '-';
                        }
                        let arr = val.split(',');

                        return arr.map(v => {
                            switch (v) {
                                case '0': return 'Restless';
                                case '1': return 'Tensed';
                                case '2': return 'Slouched';
                                case '3': return 'Relaxed';
                                case '4': return 'Jerky';
                            }
                        }).join(', ');
                    },
                    report(val) {
                        switch (val) {
                            case 0:
                                return 'Mild';
                            case 1:
                                return 'Moderate';
                            case 2:
                                return 'Namaste';
                            case 3:
                                return 'Individual';
                            default:
                                return '';
                        }
                    }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#8967b3;
$base-color-yellow :#FFCD06;
.profileImageBody{
    width:150px;
    height:150px;
}
.border_full{
    border: 0.5px solid rgb(198, 198, 198);
    padding: 10px;
}

.separator{
    background: rgb(239, 239, 239);
    padding: 10px;
    border-radius: 10px;
    justify-content: center;
    padding-top: 15px;
}


.border_full_color{
    border: 0.5px solid rgb(198, 198, 198);
    padding: 10px;
    background: #8967b3;
    color: white;
}

.no_border_full{
    border: 0.5px solid rgba(255, 255, 255, 0);
    padding: 10px;
}
.activity_list{
            margin-top: 10px;
            border: 1px solid #dbdbdb;
            padding: 10px;
            border-radius: 10px;
            width: max-content;
            width: 300px;
            
        }
.blockButton {
    background-color: #8967b3;
    width: fit-content;
    gap: 10px;
    border:none !important;
}

.headertitle {
            font-size: 22px;
            padding: 30px;
            text-align: center;
        }

        .colcolor {
            background: rgba(80, 39, 107, 0.728);
            color: white;
            text-align: center;
        }

        .darkcolcolor {
            background: rgba(80, 39, 107, 0.728);
            color: white;
        }

        .row-center {
            text-align: center;
        }

        .background-card{
            background: #ffffff;
            margin-top: 30px;
            border: 1px solid black;
        }

        .image-list{
            width: 384px;
            height:384px;
            object-fit: contain;
        }
.card-body {
    padding: 0;
    // border: none;
}
.card {
    border: none;
}
</style>