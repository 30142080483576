<template lang="pug">
b-modal(v-model="showModal", size="lg", centered  )
    template(#modal-header)
        h6.modal-title Cancel Submission Event Submission?
    div()
        small Are you sure you want to cancel the submission for the event "{{submission?.name}}"? 
        small(style="color: red;")  You will not be able to submit it again or get any refunds.
    template(#modal-footer)
        b-button(@click="showModal = false" variant="primary" :disabled="loading") Close 
        b-button( variant="danger" :disabled="loading" @click="cancelSubmission")   
            span(v-if="loading")  Loading...
            span(v-else) I understand, Cancel Submission
                


</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    submission: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    reloadFunction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    async cancelSubmission() {
      this.loading = true;
      try {
        const data = {
          client: this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid,
          caregiver: this.$root.currentUser[0].crb5c_fow_caregiverid,
          json: {
            eventID: this.event.crb5c_foweventmanagementid,
            userID: this.$root.currentUser[0].id,
            status: "Not Attending",
            caregiverName: this.$root.currentUser[0].crb5c_name,
            clientName: this.$root.currentUser[0].crb5c_client.crb5c_no,
            name: this.event.crb5c_eventname,
          },
        };

        const response = await axios.post("/api/events/v2/deleteSubmission",
          data
        );
        console.log(response);
        await this.reloadFunction();
        this.showModal = false;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
