<template lang="pug">
b-list-group-item(class="d-flex  shadow")
        div(class="profileImageBody rounded-circle mx-4")
            img(class="h-100 w-100 rounded-circle" :src="imgsrc")
        p(class="my-auto h6 font-weight-bold") {{name}}
</template>

<script>
export default {
    props: {
        name: {
            type: String,
        },
        imgsrc: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
.profileImageBody {
    width: 80px;
    height: 80px;
}

.mainBody {
    border-radius: 20px;
}
</style>