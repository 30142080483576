<template lang="pug">
div.d-flex.justify-content-start
    div(class="receiverBody d-flex flex-column w-75 m-3 p-2 rounded")
        p Automated Bot
        p.font-weight-bold The time to response to your message may be delayed, please be patient and thank you
        p {{time}}
</template>

<script>
import dayjs from "dayjs";

export default {
    data(){
        return{
            time: dayjs().format("HH:mm")
        }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;
$base-color-yellow :#FFCD06;
.receiverBody {
    background-color: $base-color-yellow;
    color:black;
}
</style>