<template lang="pug">
b-container.py-2
    b-row.align-items-center
        b-col.col-6
            span.font-weight-bold(style="font-size: 1.5rem;") Past Events
        b-col.col-6.text-right
            b-button(style="background-color: #50276B;" @click="moveToEventPage()") Current Events
    
    b-row.my-4(
            v-if="pastListData.length !== 0"
            v-for="(event, index) in pastListData" :key="event.msgId"
        )
        b-col
            b-card(
                :header-bg-variant="stateHeaderAndFooterBgColor(event, getCurrentCaregiverId())" 
                :footer-bg-variant="stateHeaderAndFooterBgColor(event, getCurrentCaregiverId())" 
                :header-text-variant="stateHeaderAndFooterTextColor()" 
                :footer-text-variant="stateHeaderAndFooterTextColor()"
            )
                template(#header)
                    BIconCalendar3Event.mr-2
                    span.font-weight-bold {{  event.title  }}
                template
                    b-row.mb-2
                        b-col
                            span(style="font-size: 1.1rem; font-weight: 600;") Price per person:
                        b-col.text-right
                            span ${{ event.pricePerPerson }}
                    b-row.mb-2
                        b-col(style="font-size: 1.1rem; font-weight: 600;")
                            span Date of Event:
                        b-col.text-right
                            span(:style="`color: ${dateColor}`") {{ formatDate(event.date) }}
                    b-row(style="font-size: 1.1rem; font-weight: 600;")
                        b-col
                            span Description: 
                    b-row.mb-2
                        b-col.text-justify
                            span(v-html="event.description")
                    b-row(style="font-size: 1.1rem; font-weight: 600;" v-if="event?.multipleDay")
                        b-col
                            span Multiple Day: 
                    b-row.mb-2(v-if="event?.multipleDay")
                        b-col.text-justify
                            span {{ event?.multipleDay?.length }} days
                    
                    b-row.mt-2.align-items-center(v-if="checkEventIsJoinAndNotJoinExcludeEventNotRespond(event, getCurrentCaregiverId()) && checkIsAttending(event, getCurrentCaregiverId())")
                        b-col
                            //- b-row.my-2(v-if="checkCaregiversContainOtherPersonJoin(event.msgId, getCurrentCaregiverId())")
                            b-row.my-2
                                b-col.col-12(style="font-size: 1.1rem; font-weight: 600;")
                                    span Person Coming
                                b-col.col-12
                                    span {{ getPersonComing(event.msgId, getCurrentCaregiverId()) }}
                                    b-row.my-2
                                b-col.col-12
                                    b-row
                                        b-col.col-12(style="font-size: 1.1rem; font-weight: 600;")
                                            span Lunch
                                        b-col.col-12
                                            b-row
                                                b-col.col-12(style="font-size: 1.1rem; font-weight: 600;")
                                                    span Main
                                                b-col.col-12
                                                    b-row
                                                        b-col.col-6
                                                            span Caregiver
                                                        b-col.col-6.text-right
                                                            span {{ getCaregiverLunchMeal(event.msgId, getCurrentCaregiverId()).main[0] ?? "Not Selected" }}
                                                    b-row
                                                        b-col.col-6
                                                            span Client
                                                        b-col.col-6.text-right
                                                            span {{ getClientLunchMeal(event.msgId, getCurrentCaregiverId()).main[0] ?? "Not Selected" }}
                                                            b-col.col-12
                                            b-row
                                                b-col.col-12(style="font-size: 1.1rem; font-weight: 600;")
                                                    span Beverage
                                                b-col.col-12
                                                    b-row
                                                        b-col.col-6
                                                            span Caregiver
                                                        b-col.col-6.text-right
                                                            span {{ getCaregiverLunchMeal(event.msgId, getCurrentCaregiverId()).beverage[0] ?? "Not Selected" }}
                                                    b-row
                                                        b-col.col-6
                                                            span Client
                                                        b-col.col-6.text-right
                                                            span {{ getClientLunchMeal(event.msgId, getCurrentCaregiverId()).beverage[0] ?? "Not Selected" }}
                            b-row.my-2(v-if="checkCaregiversContainOtherPersonJoin(event.msgId, getCurrentCaregiverId())")
                                b-col.col-12(style="font-size: 1.1rem; font-weight: 600;")
                                    span Other person joining:
                                b-col.col-12
                                    b-row.py-2.border.border-bottom-0(style="font-weight: 600;")
                                        b-col.col-4
                                            span Name
                                        b-col.col-4
                                            span Main
                                        b-col.col-4
                                            span Beverage
                                    b-row.py-2.border.align-items-center(
                                            v-for="(item, index) in getListOtherPersonJoin(event.msgId, getCurrentCaregiverId())"
                                            :key="index"
                                        )
                                        b-col.col-4
                                            span {{ item.name }}
                                        b-col.col-4
                                            span {{ item.lunch.main }}
                                        b-col.col-4
                                            span {{ item.lunch.beverage }}
                            b-row.my-2
                                b-col
                                    b-row.mb-2(style="font-size: 1.1rem; font-weight: 600;")
                                        b-col
                                            span Payment
                                    b-row.mb-2
                                        b-col.col-6
                                            span Date
                                        b-col.col-6.text-right
                                            span 11/6/23
                                    b-row.mb-2
                                        b-col.col-6
                                            span Status
                                        b-col.col-6.text-right
                                            span Paid
                                    b-row.mb-2
                                        b-col.col-6
                                            span Method
                                        b-col.col-6.text-right
                                            span Online
                                    b-row.mb-2
                                        b-col.col-6
                                            span Receipt
                                        b-col.col-6.text-right
                                            //- b-link(:href="getPaymentReceipt(event.msgId, getCurrentCaregiverId())" target="_blank") Receipt-1
                                            b-link(href="https://www.google.com" target="_blank") Receipt-1
                                            
                            b-row.justify-content-end.align-items-center(style="font-size: 1.2rem;")
                                b-col.col-8.px-0.text-right(style="font-weight: 600;")
                                    span Total price after GST 1.08%:
                                b-col.col-4.text-right
                                    //- span {{ getTotalPriceAfterGstAfterJoin(event.msgId, getCurrentCaregiverId()) }}$
                                    span $20
                template(#footer)
                    b-row
                        b-col.text-center.font-weight-bold(
                                v-if="event.caregivers.length !== 0"
                            )
                            b-row(v-if="checkIsAttending(event, getCurrentCaregiverId()) === true")
                                b-col.col-12
                                    span.mr-2 Joined
                                    b-icon(icon="check-2-square")
                            b-row(v-if="checkIsAttending(event, getCurrentCaregiverId()) === false")
                                b-col.col-12
                                    span.mr-2 Not Joining
                                    b-icon(icon="calendar2-x")
                        b-col.text-center.font-weight-bold(
                                v-else
                            )
                                span.mr-2 None

    b-row.align-items-end(v-if="pastListData.length === 0" style="height: 40vh;")
        b-col
            b-card(style="background-color: #eee;")
                template
                    b-row
                        b-col.d-flex.flex-column.align-items-center(style="font-size: 1.5rem; gap: 2rem;")
                            BIconCalendarX(font-scale="3")
                            span.text-center No past events for now

</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";
import { EVENTS_NAMESPACE } from '../store/modules/events/types-events';


export default {
    name: "PastEventsPage",
    data(){
        return {
            dateColor: '#0984e3',
        }
    },
    methods: {
        moveToEventPage(){
            this.$router.push({ path: "/events" });
        },
        getCurrentCaregiverId(){
            return this.currentUser[0].crb5c_fow_caregiverid;

        },
        formatDate(rawDate){
            return dayjs(rawDate).format('DD MMM YY, hh:mm A');
        
        },
        checkIsAttending(event, caregiverId){
            return event.caregivers
            .find(item => item.id === caregiverId)
            ?.isAttending ?? false;
        },
        stateHeaderAndFooterBgColor(event, caregiverId){
            //-------- temp solution
            if(event.caregivers.length === 0){
                return "secondary";
            }
            //-------- temp solution

            if(this.checkIsAttending(event, caregiverId) === false){
                return "secondary";
            }
            return "success";
        },
        stateHeaderAndFooterTextColor(){
            return "white";
        },
        getCaregiverItem(msgId, caregiverId){
            return this.pastListData
            .find(item => item.msgId === msgId)
            .caregivers
            .find(item => item.id === caregiverId);

        },
        getPersonComing(msgId, caregiverId){
            const isCaregiverCome = this.getCaregiverItem(msgId, caregiverId)?.isCaregiverCome ?? null;
            const isHasListOtherPersonJoining = this.getCaregiverItem(msgId, caregiverId).otherPersonJoining.length !== 0;
            
            if(!isCaregiverCome && !isHasListOtherPersonJoining){
                return "Client only";
            }

            if(!isCaregiverCome && isHasListOtherPersonJoining){
                return "Client and Other Person";
            }

            return "Caregiver and Client";

        },
        getCaregiverLunchMeal(msgId, caregiverId){
            const { main, beverage } = this.getCaregiverItem(msgId, caregiverId).listLunchSelected.caregiver;

            return {
                main, 
                beverage,
            };

        },
        getClientLunchMeal(msgId, caregiverId){
            const { main, beverage } = this.getCaregiverItem(msgId, caregiverId).listLunchSelected.client;

            return {
                main, 
                beverage,
            };

        },
        getListOtherPersonJoin(msgId, caregiverId){
            const listOtherPersonJoin = this.getCaregiverItem(msgId, caregiverId).otherPersonJoining;
            
            return listOtherPersonJoin;

        },
        checkEventIsJoinAndNotJoinExcludeEventNotRespond(event, caregiverId){
            return event.caregivers.find(item => item.id === caregiverId);

        },
        checkCaregiversContainOtherPersonJoin(msgId, caregiverId){
            const isHaveOtherPersonJoin = this.getCaregiverItem(msgId, caregiverId).otherPersonJoining.length;

            if(isHaveOtherPersonJoin){
                return true;
            }

            return false;

        },
    },
    computed: {
        ...mapState({
        currentUser: "currentUser",
        listData: state => (state[EVENTS_NAMESPACE].listData),
        }),
        //TODO: filter listData past event
        pastListData(){
            return this.listData.filter(item => dayjs().isAfter(dayjs(item.date)));
        },
    },
}
</script>

<style lang="scss" scoped>
</style>