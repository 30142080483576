<template lang="pug">
div(class="firstSection d-flex flex-column justify-content-center mt-3")
    div(class="profileImageBody rounded-circle mx-auto d-flex justify-content-center")
        img(v-if="imgsrc" class="h-100 w-100 rounded-circle" :src="imgsrc")
        b-avatar(v-else size="8rem")
    p(class="h3 my-5 mx-auto font-weight-bold") {{name}}
</template>

<script>
export default {
    data() {
        return {
            noImage: false
        }
    },
    props: {
        session: {
            type: String,
        },
        imgsrc: {
            type: String,
        },
        name:{
            type:String,
        }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;
$base-color-yellow :#FFCD06;
</style>