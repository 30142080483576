<template lang="pug">
b-modal(v-model="showModal", size="lg", centered title="Submission Details" scrollable)
    .container
            div(v-if="event.isSingleDay && submission.singleDay" class="d-flex flex-column" style="")
                 
                div(v-for="person in uniquePeopleSingleDay" :key="person"  )
                    div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="person!=='Other Persons'")
                        b-icon-person-badge
                        b-card-text.m-0() {{ person === 'Client' ? `${currentClientName()} -` : person === 'Caregiver' ? `${currentCaregiverId()} -` : "" }}  {{ person }}
                    div(v-if="submission.singleDay.whoGoing.includes('Client') && (event.singleDay.food.lunch.length>0 || event.singleDay.food.beverages.length>0) && person === 'Client'" class="d-flex flex-column" ).mt-2
                        small.p.m-0(v-if="submission.singleDay.client.lunch") Client's Lunch Option
                        b-card-text.m-0.font-weight-bold(v-if="submission.singleDay.client.lunch") {{ submission.singleDay.client.lunch }}
                        div( class="d-flex flex-column" style="" v-if="event.singleDay.food.beverages.length > 0 && submission.singleDay.client.beverage").mt-3
                            small.p.m-0(v-if="submission.singleDay.client.beverage") Client's Beverage Option
                            b-card-text.m-0.font-weight-bold(v-if="submission.singleDay.client.beverage") {{ submission.singleDay.client.beverage }}
                    hr(style="border-top: 1px solid #ccc; margin: 1rem 0;" v-if="submission.singleDay.whoGoing.includes('Client') && submission.singleDay.whoGoing.includes('Caregiver') && (event.singleDay.food.lunch.length>0 || event.singleDay.food.beverages.length>0) && person==='Client'")  
                    div(v-if="submission.singleDay.whoGoing.includes('Caregiver') && (event.singleDay.food.lunch.length>0 || event.singleDay.food.beverages.length>0) && person==='Caregiver'" class="d-flex flex-column").mt-2
                        small.p.m-0(v-if="submission.singleDay.caregiver.lunch") Caregiver's Lunch Option
                        b-card-text.m-0.font-weight-bold(v-if="submission.singleDay.caregiver.lunch") {{ submission.singleDay.caregiver.lunch }}
                        div(v-if="event.singleDay.food.beverages.length > 0 && submission.singleDay.caregiver.beverage" class="d-flex flex-column").mt-3
                            small.p.m-0(v-if="submission.singleDay.caregiver.beverage") Caregiver's Beverage Option
                            b-card-text.m-0.font-weight-bold(v-if="submission.singleDay.caregiver.beverage") {{ submission.singleDay.caregiver.beverage }}
                    hr(style="border-top: 1px solid #ccc; margin: 1rem 0;" v-if="person==='Caregiver' && submission.singleDay.whoGoing.includes('Caregiver') && submission.singleDay.whoGoing.includes('Other Persons') && submission.singleDay.otherPersons.length > 0" )
                    div(v-if="person==='Other Persons' && submission.singleDay.whoGoing.includes('Other Persons') && submission.singleDay.otherPersons.length > 0" class="d-flex flex-column" ).mb-2
                        b-card-text.h6 Other Persons
                        div(v-for="person in submission.singleDay.otherPersons" :key="person.name" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;")
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-person-badge
                                b-card-text.m-0 {{ person.name }}
                            div(class="d-flex flex-column")
                                small.mt-3(v-if="person.lunch") Lunch Option
                                b-card-text.m-0.font-weight-bold(v-if="person.lunch") {{ person.lunch }}
                                div(v-if="event.singleDay.food.beverages.length > 0 && person.beverage" class="d-flex flex-column" )
                                    small.mt-3(v-if="person.beverage") Beverage Option
                                    b-card-text.m-0.font-weight-bold(v-if="person.beverage") {{ person.beverage }}        
                small.badge-local(style="width:fit-content;" v-if="submission?.remarks").mt-4.mb-0 Remarks You Added: 
                b-card-text.p.m-0 {{ submission?.remarks }}
                small.badge-local(style="width:fit-content;").mt-3 Total Price
                b-card-text.p.m-0.text-success $ {{  (submission.totalCost * event.gst).toFixed(2) }} (after GST)
                    

            div(v-if="!event.isSingleDay && submission.multipleDays" class="d-flex flex-column" style="")
                
                small.p.mb-1.mt-3 People Attending 
                
                div(v-for="person in uniquePeopleMultipleDays" :key="person" style="")
                    div(class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-person-badge
                        b-card-text.m-0.h6 {{ person === 'Client' ? `${currentClientName()} -` : person === 'Caregiver' ? `${currentCaregiverId()} -` : "" }}  {{ person }}
                        
                
                    div(v-if="submission.multipleDays.whoGoing.includes('Client') && person ==='Client'" class="d-flex flex-column")

                        small.p.mb-1.mt-3 Selected Days for Client:
                        ul(class="list-unstyled" style="padding-left: 0;")
                            li.p.font-weight-bold(v-for="day in submission.multipleDays.client.attendingDays" :key="day") {{ day }}
                    
                        div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="submission.multipleDays.client.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column mb-2" )
                            small.p.mb-1() Selected Food Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                            b-card-text.m-0.font-weight-bold {{ submission.multipleDays.client.lunchByDay.find(lunch => lunch.dayID === day.id)?.lunchOption }}
                            b-card-text.m-0.font-weight-bold {{ submission.multipleDays.client.beverageByDay.find(beverage => beverage.dayID === day.id)?.beverageOption }}
                            small.p.mb-2.text-primary(v-if="!submission.multipleDays.client.lunchByDay.find(lunch => lunch.dayID === day.id)  && !submission.multipleDays.client.beverageByDay.find(beverage => beverage.dayID === day.id) ") No Food Options Selected for this day
                
                    hr(style="border-top: 1px solid #ccc; margin: 1rem 0;" v-if="person === 'Client' && submission.multipleDays.whoGoing.includes('Caregiver')")
                    div(v-if="submission.multipleDays.whoGoing.includes('Caregiver')  && person ==='Caregiver'" class="d-flex flex-column" )
                        small.p.mb-1.mt-3 Selected Days for Caregiver:
                        ul(class="list-unstyled" style="padding-left: 0;")
                            li.p.font-weight-bold(v-for="day in submission.multipleDays.caregiver.attendingDays" :key="day") {{ day }}
                        div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="submission.multipleDays.caregiver.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column" )
                            small.p.mb-1() Selected Food Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                            b-card-text.m-0.font-weight-bold {{ submission.multipleDays.caregiver.lunchByDay.find(lunch => lunch.dayID === day.id)?.lunchOption }}
                            b-card-text.m-0.font-weight-bold {{ submission.multipleDays.caregiver.beverageByDay.find(beverage => beverage.dayID === day.id)?.beverageOption }}
                            small.p.mb-2.text-primary(v-if="submission.multipleDays.caregiver.lunchByDay.length === 0 && submission.multipleDays.caregiver.beverageByDay.length === 0") No Food Options Selected for this day
                        //- b-card-text(v-if="!submission.multipleDays.caregiver.lunchByDay.find(lunch => lunch.dayID === day.id) && !submission.multipleDays.caregiver.beverageByDay.find(beverage => beverage.dayID === day.id)") No Food Options Selected for this day
                    hr(style="border-top: 1px solid #ccc; margin: 1rem 0;" v-if="person === 'Caregiver' && submission.multipleDays.whoGoing.includes('Other Persons')")
                    div(v-if="person === 'Other Persons' && submission.multipleDays.whoGoing.includes('Other Persons')" class="d-flex flex-column" ).mt-2
                        div(v-for="person in submission.multipleDays.otherPersons" :key="person.name" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;")
                            div(class="d-flex align-items-center" )
                                b-icon-person-badge
                                b-card-text.m-0 {{ person.name }}
                            div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="person.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column" )
                                small.p.mb-1() Selected Food Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }} 
                                b-card-text.m-0.font-weight-bold {{ person.lunchByDay.find(lunch => lunch.dayID === day.id)?.lunchOption }}
                                b-card-text.m-0.font-weight-bold {{ person.beverageByDay.find(beverage => beverage.dayID === day.id)?.beverageOption }}
                                small.p.mb-2.text-primary(v-if="!person.lunchByDay.find(lunch => lunch.dayID === day.id)  && !person.beverageByDay.find(beverage => beverage.dayID === day.id) ") No Food Options Selected for this day
                small.badge-local(style="width:fit-content;" v-if="submission?.remarks").mt-4.mb-0 Remarks You Added: 
                b-card-text.p.m-0.font-weight-bold {{ submission?.remarks }}
                small.badge-local(style="width:fit-content;").mt-3 Total Price
                b-card-text.p.m-0.text-success $ {{  (submission.totalCost * event.gst).toFixed(2) }} (after GST)
    template(#modal-footer)
        b-button(@click="showModal = false" variant="primary") Close
</template>

<script>
    export default {
        props:{
            event:{
                type:Object,
                required:true
            },
            show:{
                type:Boolean,
                required:true
            },
            submission : {
                type:Object,
                required:true
            }
        },
        computed:{
            showModal:{
                get(){
                    return this.show;
                },
                set(value){
                    this.$emit('update:show', value);
                }
            },
            uniquePeopleSingleDay() {
                const people = [...new Set(this.submission.singleDay.whoGoing)];
                const order = ['Client', 'Caregiver', 'Other Persons'];
                return people.sort((a, b) => order.indexOf(a) - order.indexOf(b));
            },
            uniquePeopleMultipleDays() {
                const people = [...new Set(this.submission.multipleDays.whoGoing)];
                const order = ['Client', 'Caregiver', 'Other Persons'];
                return people.sort((a, b) => order.indexOf(a) - order.indexOf(b));
            },

        },
        methods:{
            currentClientName() {
            return this.$store.state.cli_name;
        },
        currentCaregiverId() {
            return this.$store.state.currentUser[0]?.crb5c_name
        }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>