<template lang="pug">
div
        button(class="pinned-button d-flex align-items-center justify-content-between px-2 text-white w-100" 
            @click="showPinned = !showPinned"
            :disabled="pinned.length === 0"
            :class="{'pinned-active-button': showPinned}") 
            div(class="d-flex w-75 align-items-center px-2" style="gap: 1rem;")
                b-icon(icon="pin", size="lg")
                h6(class="m-0") {{pinned.length === 0 ? 'No Pinned Announcements' : `${pinned.length} Pinned Announcements`}}
            div(class="d-flex w-25 justify-content-end p-2")
                transition(name="icon-fade")
                    b-icon(v-if="showPinned" icon="chevron-up", size="is-large")
                    b-icon(v-else icon="chevron-down", size="is-large")
        div(v-if="showPinned && pinned.length !== 0" class="pinned-announcement d-flex flex-column w-100 h-fit max" 
            :class="{'fade-up': showPinned}"
            style="overflow-y: auto; gap: 0.5rem;")
            div(class="d-flex w-100 flex-column" style="gap: 1rem;")
                div(v-for="announcement in pinned" :key="announcement.crb5c_fowannouncementid" class="d-flex shadow-md card-announcement flex-column p-3 border-2" style="background-color: #fff; gap: 1rem;")
                    div(class="d-flex w-100 justify-content-between mt-2")
                        h5 {{announcement.crb5c_title}}
                        p(class="date-announcement") {{new Date(announcement.createdon).toLocaleDateString()}}
                    div(v-html="truncateText(JSON.parse(announcement.crb5c_body), 200)")
                    div(class="d-flex w-100 align-items-center")
                        p(class="view-more m-0 p-0" @click="showModalAnnouncement(announcement)") Read More
                        b-icon(icon="chevron-right")

</template>
<script>
    export default {
    props: {
        pinned: {
            type: Array,
            required: true
        },
        showModal: {
            type: Boolean,
            required: true
        }
    },
        data() {
            return {
                showPinned: false, 
            };
        },
        computed:{
            showModalData: {
                get() {
                    return this.showModal
                },
                set(value) {
                    this.$emit('update:showModal', value)
                }
            },
        },
        watch: {
            pinned:{
                handler(val){
                    // console.log('pinned',val)
                    this.showPinned = val.length > 0;
                },
                immediate: true
            },
        },
        methods: {
            showModalAnnouncement(announcement) {
                this.$emit('update:selectedAnnouncement', announcement);
            },
            truncateText(text, length) {
                return text.length > length ? text.substring(0, length) + '...' : text;
            },
        },
        
    
        
    }
</script>

<style lang="scss" scoped>
.pinned-button{
    width: 100%;
    height: 10vh;
    background-color: #8967b3;
    outline: none;
    border: none;
    border-radius: 10px;
}
.pinned-active-button{
    border-radius: 10px 10px 0px 0px;
}
@keyframes icon-fade {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}
@keyframes icon-fade-up {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}
.pinned-announcement {
    background-color: #8967b3;
    border-radius: 0px 0px 10px 10px;
    padding: 1rem;
    gap: 1rem;
    animation: icon-fade 0.5s ease-in-out;
    transform-origin: top;
}
.fade-up{
    animation: icon-fade-up 0.5s ease-in-out;
    transform-origin: top;
}
.date-announcement{
    font-size: smaller;
}
.view-more{
    color:#8967b3;
    width: fit-content;
    border-bottom: 1px solid #8967b3;
    font-size: smaller;
}

.bi-chevron-right{
    color: #8967b3;
}
.card-announcement{
    border: 0.5px solid #8967b3;
    border-radius: 10px;
    
    
}
.spinner-border{
    //color #8967b3

    border: 0.15rem dashed #8967b3;
}
.title-name{
    
}
.form-input-month{
    width: 50%;
    height: 5vh;
    border: 1px solid #8967b3;
    border-radius: 10px;
}
</style>