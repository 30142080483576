<template lang="pug">
.mainBody(class="bg-dark" v-if="invoices.id" style="overflow-wrap: break-word; min-width:1190px;" onfocusout="parent.setEmailRange();")
    .topbar.p-3.d-flex.sticky-top
        b-button.p-3.mx-auto.btn-lg(@click="printinv") Print/Save as PDF
    b-container.bg-white.p-5.mb-5.d-flex.flex-column
        div.px-5.mb-auto
            h1.text-center.font-weight-bold Tax Invoice
            img(class="w-25" src="@/assets/new_dsg-logo.jpg")
            div.my-5
                p.mb-0 Dementia Singapore Ltd,
                p.mb-0 20 Bendemeer Road,
                p.mb-0 #01-02 BS Bendemeer Centre,
                p.mb-0 Singapore 339914
                p.mb-0 GST Reg No. 202111519K
            p.h4.text-left.font-weight-bold Bill To
            div.d-flex.justify-content-around
                b-col.bg-light.p-3.rounded
                    p.mb-0 {{invoices.client_name}}
                    p.mb-0 {{invoices.client_address_line1}}
                    p.mb-0 {{invoices.client_address_line2}}
                b-col.pl-4
                    dl.row.mb-1
                        dt.col-4 Invoice No: 
                        dd.col-8 {{invoices.id}}
                    dl.row.mb-1
                        dt.col-4 Date: 
                        dd.col-8 2022-10-28
                    dl.row.mb-1
                        dt.col-4 Due Date: 
                        dd.col-8 3353874
                    dl.row.mb-1
                        dt.col-4 Terms:
                        dd.col-8 30D
            b-table-simple.mt-3
                b-thead(variant="dark")
                    b-tr
                        b-th.col-6 Description
                        b-th.col-1 GST
                        b-th.col-1 QTY
                        b-th.col-1 RATE
                        b-th.col-3.text-right AMOUNT
                b-tbody
                    b-tr
                        b-td 
                            p {{invoices.description}}
                            p.mb-0.ml-4(v-for="(date,i) in invoices.sessions" :key="i+'_sessions'") {{date}}
                        b-td {{invoices.gst}}% 
                        b-td {{invoices.qty}}
                        b-td {{invoices.amount.toFixed(2)}}
                        b-td.text-right {{invoices.subtotal.toFixed(2)}}
            b-row
                b-col(cols="4" offset="8")
                    b-table-simple
                        b-tbody
                            b-tr
                                b-td SUBTOTAL (SGD)
                                b-td.text-right {{invoices.subtotal.toFixed(2)}}
                            b-tr
                                b-td GST TOTAL (SGD)
                                b-td.text-right {{invoices.gst_amt.toFixed(2)}}
                            b-tr
                                b-td.font-weight-bold TOTAL (SGD)
                                b-td.text-right {{invoices.total.toFixed(2)}}
        h5.text-center.mt-5 This is a computer generated invoice. No signature is required.
        p.text-center.mt-auto 1 of 2
    b-container.bg-white.p-5.mb-5.d-flex.flex-column(style="min-height:1400px;")
        div.px-5
            h1.text-right.font-weight-bold Statement Balance as of {{now}}
            b-table-simple.small
                b-thead.bg-light
                    b-tr
                        b-th(style="width:100%")
                        b-th.text-right Amount (SGD)
                b-tbody
                    b-tr
                        b-td.text-right.border-0 Invoice Amount:
                        b-td.text-right.border-0 {{invoices.total.toFixed(2)}}
                    b-tr(v-for="(deduction,i) in invoices.list_all")
                        b-td.text-right.border-0 LESS {{deduction.type === 'cn' ? 'Credit Note':'Payment'}} on {{(deduction.date)}}:
                        b-td.text-right.border-0 ({{deduction.amt.toFixed(2)}})
                    b-tr
                        b-td.text-right.border-0.font-weight-bold Outstanding Balance
                        b-td.text-right.border-0.font-weight-bold {{invoices.due.toFixed(2)}}
            div.my-3 
                h1(style="font-weight:700;") Payment Instructions
                h4.text-danger(style="font-weight:700;") Payment via QR code is highly recommended.
                p Kindly make payment by scanning the PayNow QR code below with a mobile banking application, or making an electronic funds transfer (FAST), to the bank account below.
                hr
                p.my-2.text-danger IMPORTANT: For electronic funds transfer, please indicate invoice number as payment reference.
                h4.text-danger(style="font-weight:700; text-decoration:underline;")
                    u {{invoices.id}}
            div.d-flex.px-5.mx-2.my-4
                b-table-simple.small.borderless(style="max-width:50%;")
                    b-tr
                        b-td Bank account name:
                        b-td Dementia Singapore Ltd - Acc 1
                    b-tr
                        b-td Bank account number:
                        b-td 451-312-912-7
                    b-tr
                        b-td UEN for PayNow:
                        b-td 202111519KDSG
                    b-tr
                        b-td Bank:
                        b-td United Overseas Bank Limited
                    b-tr
                        b-td Branch code:
                        b-td 001
                    b-tr
                        b-td Bank address:
                        b-td
                            | 80 Raffles Place
                            br
                            | Singapore 048624
                    b-tr
                        b-td Bank code:
                        b-td 7375
                    b-tr
                        b-td Bank SWIFT Code:
                        b-td UOVBSGSG
                div(class="ml-auto qrcode")
                    vue-qrcode(:value="paynowString" :options="qroptions")
                    img(src="@/assets/new_paynow.png")
            hr
            p For Cheque Payment:
            p.mb-1 All cheques should be crossed and made payable to
                b.text-danger.ml-1 Dementia Singapore Ltd - Acc 1
            p Kindly indicate name of the client and invoice number at the back of the cheque.
        h5.text-center.mt-5 End of document 
        p.text-center.mt-auto 2 of 2
</template>

<script>
import dayjs from "dayjs"
import VueQrcode from '@chenfengyuan/vue-qrcode';
import PaynowQR from 'paynowqr'
export default{
    components: {
        VueQrcode
    },
    data(){
        return{
            now: dayjs().format("DD MMMM YYYY")
        }
    },
    computed:{
        paynowString() {
            let qrcode = new PaynowQR({
                uen: '202111519KDSG',           //Required: UEN of company
                amount: this.invoices.due,               //Specify amount of money to pay.
                refNumber: this.invoices.id,   //Reference number for Paynow Transaction. Useful if you need to track payments for recouncilation.
                company: 'Dementia Singapore Ltd. - Acc 1'   //Company name to embed in the QR code. Optional.               
            });
            return qrcode.output();
        },
        qroptions() {
            return {
                errorCorrectionLevel: "H",
                color: {
                    dark: "#7C1978"
                }
            }
        }
    },
    methods:{
        screenChange(){
            document.querySelectorAll('meta')[2].setAttribute('content', '')
        },
        printinv() {
            window.print()
        }
    },
    inject: ['invoices'],
    created(){
        this.screenChange();
    }
}
</script>

<style lang="scss" scoped>
.container {
    min-height: 1400px;
    min-width: 1044px !important;
}

.qrcode {
    position: relative;
}

.qrcode img {
    height: 20%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.topbar {
    background-color: #000;
}

@media print {
    .container {
        break-after: always;
        break-inside: avoid;
    }

    .topbar,
    .topbar * {
        display: none !important;
        ;
    }

    .container,
    .container * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
}
</style>