<template lang="pug">
div(class="dateTimeBody")
    div.mt-3(class="d-flex")
        b-icon(class="mr-4 h6" variant="warning" icon='calendar2-event-fill')
        p.h6.mr-3 Date:
        p.font-weight-bold.ml-1.h6 {{formatDate(date)}}
    //- div(class="d-flex")
    //-     b-icon(class="mr-4 h6 font-weight-bold" variant="warning" icon='arrow-right')
    //-     p.h6.mr-3 Check in:
    //-     p.font-weight-bold.ml-1.h6 {{formatDate(checkin, 'checkin')}}
    //- div(class="d-flex")
    //-     b-icon(class="mr-4 h6" variant="warning" icon='arrow-left')
    //-     p.h6.mr-3 Check out:
    //-     p.font-weight-bold.ml-1.h6 {{formatDate(checkout, 'checkout')}}
</template>

<script>
export default {
    methods: {
        formatDate(date, flag = null) {
            const dayjs = this.$root.dayjs;
            const format = 'DD/MM/YYYY';
            const formatTime = 'h:mm A';


            if (!date || !this.date) {
                return '';
            }

            switch (flag) {
                case 'checkin': {
                    if (dayjs(this.date).add(1, 'hour').isSameOrAfter(dayjs(date))) {
                        return dayjs(date).format(formatTime)
                    }
                    return dayjs(this.date).format(formatTime);
                }
                case 'checkout': {
                    if (dayjs(this.date).add(this.duration, 'minute').isSameOrBefore(dayjs(date))) {
                        return dayjs(date).format(formatTime);
                    }
                    return dayjs(this.date).add(this.duration, 'minute').format(formatTime);
                }
                default: {
                    return dayjs(date).format(format);
                }
            }
        },
    },
    props: {
        date: {
            type: String,
        },
        // checkin: {
        //     type: String,
        // },
        // checkout: {
        //     type: String,
        // },
        // duration: {
        //     type: Number
        // }
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;
$base-color-yellow :#FFCD06;

.profileImageBody {
    width: 150px;
    height: 150px;
}
</style>