<template lang="pug">
div(class="mainBody")
    b-container
        router-link(to="/home")
            b-button(block class="blockButton mt-3 font-weight-bold")
                b-icon.m-auto(icon="arrow-left-short")
                | Back
        p.font-weight-bold.h3.my-3 Reminders (Sample)
        //- Client List 
        reminder-list(v-for="reminder in reminders"
        :key="reminder.id"
        :title="reminder.title"
        :date="reminder.date"
        :desc="reminder.desc")

</template>

<script>
// @ is an alias to /src
import ReminderList from '@/components/reminder/ReminderList.vue'

export default {
    components: {
        ReminderList,
    },
    inject: ['reminders'],
    async mounted() {
        await this.$root.loginAndFetch();
    }
}
</script>

<style lang="scss" scoped>
$base-color-purple :#50276B;
$base-color-yellow :#FFCD06;
.blockButton {
    background-color: transparent;
    color: $base-color-purple;
    border: 2px solid $base-color-purple;
}
</style>le