<template lang="pug">
div(class="mainBody" style="height:90vh; overflow:auto;")
    b-container
        div
            b-card.my-3(header-bg-variant="dsg-light" header-text-variant="white")
                template(#header)
                    div.d-flex
                        h5 
                            //- b-icon.mr-2(icon="telephone")
                            span Contact Us
                b-card.p-3(no-body)
                    p Office:
                    div
                        p.font-weight-bold.ml-1(@click='callNo("+6563895385")') (65) 6389 5385
                            span.mx-2
                                b-icon.mr-2(icon="telephone-fill")
                        p.font-weight-bold.ml-1(@click='callNo("+6580205006")') (65) 8020 5006
                            span.mx-2 
                                b-icon.mr-2(icon="telephone-fill")
                    div(class="infoBody d-flex")
                    p(style="font-size: 0.8rem;") Available 9:00 AM to 6:00 PM, closed on public holiday.
            hr.thicker-hr
            b-card.mb-3(header-bg-variant="dsg-light" header-text-variant="white")
                template(#header)
                    div.d-flex
                        h5 
                            //- b-icon.mr-2(icon="inbox")
                            span Email Us
                b-form
                    b-form-group(id="message-group" label-for="input-message-box")
                        b-form-textarea(id="input-message-box" rows="5" max-rows="10" placeholder="Enter your message" v-model="text")
                    div.d-flex.justify-content-center
                        b-button.font-weight-bold(:disabled="sending || !text || !selected" class="sendButton px-4 py-2" @click="sendMail") Send

            b-card-text.my-2 Having Issues With the App?
            a(href="https://wa.me/message/WUVWRHF67KYTE1" target="_blank").w-100
                b-button.w-100.mb-4(style="background-color:#8967b3;") Report Here

            //- alternative option
            //- div(class="issues-button d-flex align-items-center my-4")
            //-     b-card-text.my-2 Having Issues With the App?
            //-     a(href="https://wa.me/message/WUVWRHF67KYTE1" target="_blank") Report Here
</template>
        
<script>
// @ is an alias to /src
import axios from 'axios';
import BackButton from '@/components/BackButton.vue';
export default {
    data(){
        return{
            msg_type:"",
            selected:'other',
            text:'',
            sending: false
        }
    },
    formdata:{

    },
    components: {
        BackButton
    },
    async mounted() {
        await this.$root.loginAndFetch();
    },
    methods: {
        callNo(num){
            window.location.href = `tel:${num}`;
        },
        async sendMail() {
            try {
                this.sending = true;
    
                let {data} = await axios.post('/api/contact_email', {
                    reason: this.selected,
                    details: this.text,
                    client_name: this.$store.state?.caregiver?.name || '',
                    caregiver_name: this.$store.state?.cli_name || ''
                });
    
                if (data.success) {
                    alert("Email sent!")
                    this.sending = false;
                    this.$router.push('/home');
                }
                
            } catch (error) {
                this.handleAxiosError(error, () => {
                    alert("Fail sending email!")
                    this.sending = false;
                    // this.$router.push('/home');
                });
            }
        },
        handleAxiosError(error, callback){
            // const BAD_REQUEST = 400;
            // const UNAUTHORIZED = 401;

            if(error.response){
                // const { data } = error.response;
                // console.error(data);

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);

                console.error(error.response);
                callback();
                
                return;
            }

            if(error.request){
                console.error(error.request);
                callback();

                return;
            }

            console.error(error);

            callback();

        }
    }
}
</script>
        
<style lang="scss" scoped>
$base-color-purple :#50276B;

.messagingButton{
    background-color: $base-color-purple;
}

.thicker-hr {
    height: 1px;
    background-color: #000;
    border: none;
}

#input-1, #input-2{
    background-color: #B5b5b5;
    border-radius: 18px;
}
.sendButton {
    background-color: $base-color-purple;
}
.issues-button{
    gap:10px;
}
</style>