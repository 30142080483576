<!-- USED IN ClientPage.vue -->
<template lang="pug">
div(class="mainBody d-flex px-3 py-2 m-4 border border-dark shadow")
        div(class="profileImageBody rounded-circle mx-3")
            img(class="h-100 w-100 rounded-circle" :src="imgsrc" @error="fallbackImg")
        p(class="my-auto h5 font-weight-bold") {{name}}
</template>

<script>
import fallbackClientImg from '@/assets/user.png'
export default{
    props:{
        name:{
            type:String,
        },
        imgsrc: {
            type: String,
        }
    },
    methods: {
        fallbackImg(e) {
            e.target.src = fallbackClientImg;
        }
    }
}
</script>

<style lang="scss" scoped>
.profileImageBody {
    width: 80px;
    height: 80px;
}

.mainBody{
    border-radius: 20px;
}
</style>